import jsoneditor from './widgets/jsoneditor.js'
import partialdate from './widgets/partialdate.js'
import './widgets/cgp-radiogroup.jsx'
import './widgets/react-select.jsx'
import './widgets/cgp-expression.jsx'

export default (SurveyCore) => {
  partialdate(SurveyCore)
  jsoneditor(SurveyCore)
}
