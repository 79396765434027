import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"
import Selector from "./selector"
import Visualizer from "./visualizer"
import CollectorLegendModal from "../../collector_legend_modal"
import { xFetch, WithSpinner } from "../../utils"
import { diff } from "jsondiffpatch/with-text-diffs"
import ViewsSelector from "./views_selector"
import OptionsManager from "./options_manager"

export default class ProjectDataVisualizer extends React.Component {

  static displayName = "ProjectDataVisualizer"

  static propTypes = {
    cohorts: PropTypes.array.isRequired,
    collectors: PropTypes.object.isRequired,
    privileges: PropTypes.object.isRequired,
    projectId: PropTypes.number.isRequired,
    viewId: PropTypes.number,
    personIdName: PropTypes.string.isRequired,
    otherPersonIdNames: PropTypes.array,
    fieldNameRegexp: PropTypes.string.isRequired
  }

  get defaultState() {
    return {
      checkedNodes: {},
      expandedNodes: {},
      checkedCohorts: [],
      checkedIds: [this.props.personIdName],
      views: [],
      currentViewId: undefined,
      selectedRowIds: undefined,
      liftUpHistories: {},
      options: {
        showPersonIdsInAllSheets: true,
        showAllAncestorsKeys: false,
        showSequentialIds: false
      }
    }
  }

  constructor(props) {
    super(props)
    this.fieldNameRegexp = new RegExp(props.fieldNameRegexp)
    this.state = this.defaultState
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    }, () =>     xFetch(Routes.project_data_visualizer_views_path(this.props.projectId))
      .then(data => {
        this.setState({
          views: data,
          isLoading: false
        },
        () => this.props.viewId && this.handleChangeView(this.props.viewId))
      })
    )
  }

  updateAddressBar = () => {
    let newUrl=new URL(window.location)
    if (this.state.currentViewId) {
      newUrl.searchParams.set("view_id", this.state.currentViewId)
    } else {
      newUrl.searchParams.delete("view_id")
    }
    history.pushState({}, null, newUrl)
  }

  get allIds() {
    return [this.props.personIdName, ...this.props.otherPersonIdNames]
  }

  refreshCollectorData = _.memoizeDebounce((cId, refreshContents = false) => {
    if (!_.isEmpty(this.state.checkedNodes[cId]) && !_.isEmpty(this.state.checkedCohorts)) {

      const visualizerStateClone = _.cloneDeep(this.state[`visualizer-${cId}`])

      let newVisualizerState = {
        datatableState: visualizerStateClone?.datatableState || {},
        contents: visualizerStateClone?.contents,
        isLoading: refreshContents
      }

      this.setState({[`visualizer-${cId}`]: newVisualizerState}, () => refreshContents &&
        xFetch(Routes.project_data_visualizer_select_path(this.props.projectId, cId), { query: {cohort_ids: _.join(this.state.checkedCohorts)} })
          .then(res => {

            newVisualizerState.isLoading = false
            newVisualizerState.contents = _.map(res,"data")

            this.setState( prevState => ({
              [`visualizer-${cId}`]: newVisualizerState
            }))
          })
      )

    } else {

      this.setState({[`visualizer-${cId}`]: undefined})

    }
  },1000)

  handleCollectorCheckboxExpand = (cId, expanded) => {
    this.setState( prevState => {
      let res = {
        expandedNodes: {...prevState.expandedNodes, [cId]: expanded }
      }
      !expanded.includes(cId) && delete(res.expandedNodes[cId])
      return res
    },this.updateCurrentViewChanged)
  }

  handleCollectorCheckboxChange = (cId, checked) => {
    const loadContents = _.isNil(this.state.checkedNodes[cId]) // load contents from server when a new collector is involved
    this.setState( prevState => {
      let res = {
        checkedNodes: {...prevState.checkedNodes, [cId]: checked }
      }
      _.isEmpty(res.checkedNodes[cId]) && delete(res.checkedNodes[cId])
      return res
    },() => this.refreshCollectorData(cId,loadContents))
  }

  handleCohortCheckboxChange = (checked) => {
    checked = _.map(checked, cId => cId.toString()) // make sure cId is saved as string
    this.setState( prevState => ({
      checkedCohorts: checked,
      selectedRowIds: _.keys(prevState.checkedCohorts) > _.keys(checked) ? {} : prevState.selectedRowIds,
    }),
    () => _.forOwn(this.state.checkedNodes, (checkedNodes, cId) => this.refreshCollectorData(cId,true))
    )
  }

  handleIdCheckboxChange = (checked) => {
    this.setState({
      checkedIds: _.isEmpty(checked) ? [this.allIds[0]] : checked
    })
  }

  getViewDataFromState = () => _.pick(this.state, ["checkedNodes","expandedNodes","checkedCohorts","checkedIds","selectedRowIds","liftUpHistories","options"])

  handleCreateView = (name,isPublic) => {
    const payload = {
      data_view: {
        name: name,
        private: !isPublic,
        data: this.getViewDataFromState()
      }
    }
    return xFetch(Routes.project_data_visualizer_views_path(this.props.projectId), {
      method: "POST",
      body: JSON.stringify(payload)
    }).then(data => {
      toastr.info("View correctly created")
      this.setState({
        views: this.state.views.concat(data),
        currentViewId: data.id
      }, () => this.updateAddressBar())
    }).catch(error => {
      toastr.error(error.msgs.join("<br>"))
    })
  }

  handleUpdateView = (name,isPublic) => {
    const payload = {
      data_view: {
        name: name,
        private: !isPublic,
        data: this.getViewDataFromState()
      }
    }
    return xFetch(Routes.project_data_visualizer_view_path(this.props.projectId,this.state.currentViewId), {
      method: "PATCH",
      body: JSON.stringify(payload)
    }).then(data => {
      toastr.info("View correctly updated")
      this.setState({
        views: this.state.views.map(v => v.id == this.state.currentViewId ? data : v)
      })
    }).catch(error => {
      toastr.error(error.msgs.join("<br>"))
    })
  }

  handleDeleteView = () => {
    return xFetch(Routes.project_data_visualizer_view_path(this.props.projectId,this.state.currentViewId), {
      method: "DELETE"
    }).then(data => {
      toastr.info("View correctly deleted")
      this.setState({
        views: _.reject(this.state.views, ["id", this.state.currentViewId])
      }, () => this.handleChangeView())
    }).catch(error => {
      toastr.error(error.msgs.join("<br>"))
    })
  }

  handleDuplicateView = () => {
    const currentView = this.state.views.find( v => v.id == this.state.currentViewId )
    this.handleCreateView(`copy of ${currentView.name}`, false)
  }

  handleChangeView = viewId => {

    if (viewId) {
      let selectedView = this.state.views.find( v => v.id == viewId )

      if (!selectedView) {
        toastr.error(`Cannot find view with id ${viewId}`)
        this.updateAddressBar()
        return
      }

      this.setState({
        isLoading: true
      }, () => Promise.resolve(
          selectedView.data ?
            selectedView
            :
            xFetch(Routes.project_data_visualizer_view_path(this.props.projectId,selectedView.id))
        ).then(view => {
          selectedView.data = view.data // save data for later selection
          let newState = {
            currentViewId: view.id,
            checkedNodes: view.data.checkedNodes,
            expandedNodes: view.data.expandedNodes,
            checkedCohorts: view.data.checkedCohorts,
            checkedIds: _.isEmpty(view.data.checkedIds) ? this.allIds : view.data.checkedIds,
            selectedRowIds: view.data.selectedRowIds,
            liftUpHistories: view.data.liftUpHistories,
            options: view.data.options || {},
            isLoading: false
          }
          Object.keys(this.props.collectors).forEach(cId => newState[`visualizer-${cId}`] = {...view.data[`visualizer-${cId}`]})
          this.setState(newState,
            () => {
              Object.keys(this.props.collectors).forEach(cId => this.refreshCollectorData(cId,true))
              this.updateAddressBar()
            }
          )
        })
      )

    } else {
      let newState = _.merge(this.defaultState, _.pick(this.state, "views"))
      Object.keys(this.props.collectors).forEach(cId => newState[`visualizer-${cId}`] = undefined)
      this.setState(newState, this.updateAddressBar)
    }

  }

  handleDatatableStateChange = ({pageIndex, pageSize, selectedRowIds, sortBy, cId, sheetId}) => {
    if (!_.isEqual(this.state[`visualizer-${cId}`].datatableState[sheetId],{pageIndex, pageSize, sortBy}) || !_.isEqual(this.state.selectedRowIds,selectedRowIds) ) {
      this.setState(prevState => {
        let newVisualizerState = prevState[`visualizer-${cId}`]
        newVisualizerState.datatableState[sheetId] = {pageIndex, pageSize, sortBy}
        return {
          [`visualizer-${cId}`]: newVisualizerState,
          selectedRowIds: selectedRowIds
        }
      })
    }
  }

  handleLiftUpHistoryChange = ({liftUpHistory, cId}) => {
    this.setState(prevState => ({
      liftUpHistories: {...prevState.liftUpHistories, [cId]: liftUpHistory}
    }))
  }

  handleOptionsChange = options => {
    this.setState({
      options: options
    })
  }

  handleShowLegend = cId => {
    this.setState({
      showLegendId: cId
    })
  }

  render() {
    // console.debug("rendering ProjectDataVisualizer")

    const
      currentView = this.state.views.find( v => v.id == this.state.currentViewId),
      currentViewChanges = currentView && diff(this.getViewDataFromState(), currentView.data)

    if (!!currentViewChanges) {
      console.debug("Current view changed: ", currentViewChanges)
      toastr.error("Remember to save it to keep changes", "Current view has been changed!", {timeOut : 0, extendedTimeOut: 0, toastClass: "toast view-changes"})
    } else {
      $("#toast-container .toast.view-changes").click()
    }

    return (
      <WithSpinner isLoading={this.state.isLoading}>
        <Container fluid>
          {
            <CollectorLegendModal
              collector={ this.state.showLegendId ? {
                legend: this.props.collectors[this.state.showLegendId]?.legend,
                title: this.props.collectors[this.state.showLegendId]?.data_model?.title
              } : undefined }
              handleClose={() => this.handleShowLegend()}
            />
          }
          <ViewsSelector
            list={this.state.views}
            currentView={currentView}
            isCurrentViewChangedContent={!!currentViewChanges}
            onCreate={this.handleCreateView}
            onUpdate={this.handleUpdateView}
            onSelect={this.handleChangeView}
            onDelete={this.handleDeleteView}
            onDuplicate={this.handleDuplicateView}
          />

          <Selector
            disabled={this.state.isLoading || (currentView && !currentView.manageable)}
            projectId={this.props.projectId}
            cohorts={this.props.cohorts}
            collectors={_.mapValues(this.props.collectors,"data_model")}
            collectorTags={_.mapValues(this.props.collectors,"tags")}
            handleShowLegend={this.handleShowLegend}
            ids={this.allIds}
            personIdName={this.props.personIdName}
            checkedNodes={this.state.checkedNodes}
            expandedNodes={this.state.expandedNodes}
            checkedCohorts={this.state.checkedCohorts}
            checkedIds={this.state.checkedIds}
            liftupHistoriesData={this.state.liftUpHistories}
            collectorsLoadingStatus={_.mapValues(this.props.collectors,(c, cId) => this.state[`visualizer-${cId}`]?.isLoading )}
            handleCollectorCheckboxChange={this.handleCollectorCheckboxChange}
            handleCohortCheckboxChange={this.handleCohortCheckboxChange}
            handleIdCheckboxChange={this.handleIdCheckboxChange}
            handleCollectorCheckboxExpand={this.handleCollectorCheckboxExpand}
          />
          <OptionsManager
            options={this.state.options}
            handleOptionsChange={this.handleOptionsChange}
            disabled={this.state.isLoading || (currentView && !currentView.manageable)}
          />
          {
            Object.keys(this.props.collectors).map(cId => {
              const vData = this.state[`visualizer-${cId}`]
              {
                return vData && !_.isEmpty(this.state.checkedNodes[cId]) ?
                  <Visualizer
                    projectId={this.props.projectId}
                    currentView={currentView}
                    checkedCohorts={this.state.checkedCohorts}
                    collectorId={parseInt(cId)}
                    isCurrentViewChangedContent={!!currentViewChanges}
                    key={cId}
                    model={this.props.collectors[cId].data_model}
                    workbook={this.props.collectors[cId].workbook}
                    legend={this.props.collectors[cId].legend}
                    ids={this.state.checkedIds}
                    personIdName={this.props.personIdName}
                    privileges={this.props.privileges}
                    selectedRowIds={this.state.selectedRowIds}
                    onlyRead={currentView && !currentView.manageable}
                    handleDatatableStateChange={this.handleDatatableStateChange}
                    handleLiftUpHistoryChange={this.handleLiftUpHistoryChange}
                    liftUpHistoryData={this.state.liftUpHistories[cId]}
                    options={this.state.options}
                    fieldNameRegexp={this.fieldNameRegexp}
                    nodeIds={this.state.checkedNodes[cId]}
                    isLoading={this.state.isLoading}
                    {...vData}
                  />
                  :
                  null
              }
            })
          }
        </Container>
      </WithSpinner>
    )
  }
}
