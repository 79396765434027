import React from 'react'
import PropTypes from 'prop-types'
import AddNamedPersonWidget from './add_named_person_widget'
import AddPersonWidget from './add_person_widget'

export default function AddPerson({
  personSjsModel,
  canAccessOsr,
  anonym,
  availableCohorts,
}) {
  return (
    anonym
      ? (
        <AddPersonWidget
          afterAddPerson={person => window.location = Routes.edit_person_path(person.id)}
          availableCohorts={availableCohorts}
          personSjsModel={personSjsModel}
        />
        )
      : (
        <AddNamedPersonWidget
          afterAddPerson={person => window.location = Routes.edit_person_path(person.id)}
          availableCohorts={availableCohorts}
          canAccessOsr={canAccessOsr}
          personSjsModel={personSjsModel}
        />
        )
  )
}

AddPerson.propTypes = {
  personSjsModel: PropTypes.object.isRequired,
  canAccessOsr: PropTypes.bool,
  anonym: PropTypes.bool.isRequired,
  availableCohorts: PropTypes.array,
}
