import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as SurveyCore from 'survey-core'
import * as SjsUtils from '../../sjs_extensions/sjs_utils'
import { Survey } from 'survey-react-ui'
import ConnectionErrorsHeader from './connection_errors_header'
import { markdownHandler, xFetch, keepSessionAlive, WithSpinner } from '../utils'
import { Tooltip, OverlayTrigger, ButtonGroup, ButtonToolbar, Button } from 'react-bootstrap'
import 'survey-core/survey.i18n'
import 'survey-core/defaultV2.css'
import { surveyLocalization } from 'survey-core'

// Get the Italian locale. To get the default locale, pass an empty string.
const itLocale = surveyLocalization.locales['it']
// Override individual translations
itLocale.completeText = 'Invia'
// add custom translations
itLocale.undo = 'Annulla cambiamnenti'
itLocale.undoTip = 'Annulla cambiamnenti e ripristina all\'ultima verione salvata'

// Get the English locale. To get the default locale, pass an empty string.
const enLocale = surveyLocalization.locales['en']
enLocale.undoTip = 'Undo changes and restore the last saved data'
enLocale.undo = 'undo changes'

let tmpWaitingForNetworkQuestions = []

function QuestionnaireContent({
  content,
  actionPaths,
  sjsModel,
  metaData,
  questionnaire,
  locale,
}) {
  const [isWorking, setIsWorking] = useState(true)
  const [lastForcedUpdate, setLastForcedUpdate] = useState(Date.now)
  const wipDataKey = `cgp-questionnaire-${questionnaire.id}-wip-data`

  const _NoConnectionProblems = () => _.isEmpty(window.survey?.questionsWithConnectionProblems)
  const _isEditable = !!actionPaths.save && _NoConnectionProblems()
  const gewWipData = () => JSON.parse(window.localStorage.getItem(wipDataKey) || null)
  const notifySurveyUpdate = () => setLastForcedUpdate(Date.now)

  const rebuildSurvey = () => {
    setIsWorking(true)
    window.survey = buildSurvey()
    window.survey.runConditions() // force runConditions because some of them may not be run until survey.setupIsFinished become true
    notifySurveyUpdate()
  }

  useEffect(() => {
    SjsUtils.setupForQuestionnaire(SurveyCore, tmpWaitingForNetworkQuestions)
    rebuildSurvey()
  }, [])

  useEffect(() => updateLoadingState(), [lastForcedUpdate])

  const updateLoadingState = _.debounce(() => {
    console.debug('updateLoadingState')
    if (window.survey.waitingForNetworkQuestions.length > 0) {
      setIsWorking(true)
    }
    else {
      setIsWorking(false)
      window.survey.mode = _isEditable ? 'edit' : 'display'
      window.survey.clearIncorrectValues()
    }
  }, 500)

  const buildSurvey = () => {
    const baseSjsModel = {
      clearInvisibleValues: 'onHiddenContainer',
      requiredText: '* ',
      showPageTitles: true,
      focusFirstQuestionAutomatic: false,
      showQuestionNumbers: 'on',
      showTitle: true,
      storeOthersAsComment: false,
      questionDescriptionLocation: 'underInput',
      firstPageIsStarted: true,
      showTOC: sjsModel?.pages?.length > 2,
      widthMode: 'responsive',
      locale: locale,
    }

    const mergedSjsModel = _.merge(baseSjsModel, sjsModel)

    console.debug('Creating survey...')
    let survey = new SurveyCore.Model(mergedSjsModel)

    console.debug('Setting up survey...')
    setupSurvey(survey)
    survey.setupIsFinished = true

    return survey
  }

  const saveWipData = () => {
    window.localStorage.setItem(wipDataKey, JSON.stringify(
      _.pick(window.survey, 'data')
    ))
  }

  const setupSurvey = (survey) => {
    survey.applyTheme('defaultV2')
    survey.css = SjsUtils.customizeCssClasses(SurveyCore.defaultV2Css)

    survey.questionsWithConnectionProblems = {}

    // set variables for metadata
    _.forOwn(metaData, (v, k) => survey.setVariable(`meta_${k}`, v))

    // after survey is built waitingForNetworkQuestions is initialized with elements collected by SurveyCore.ChoicesRestfull.onBeforeSendRequest callback which run before
    survey.waitingForNetworkQuestions = tmpWaitingForNetworkQuestions
    tmpWaitingForNetworkQuestions = []

    survey.addWaitingForNetworkQuestion = (id) => {
      survey.waitingForNetworkQuestions.push(id)
      notifySurveyUpdate()
      console.debug('addWaitingForNetworkQuestion: ', id, survey.waitingForNetworkQuestions)
    }

    survey.removeWaitingForNetworkQuestion = (id) => {
      survey.waitingForNetworkQuestions = _.without(survey.waitingForNetworkQuestions, id)
      notifySurveyUpdate()
      console.debug('removeWaitingForNetworkQuestion: ', id, survey.waitingForNetworkQuestions)
    }

    survey.addQuestionWithConnectionProblems = (question, data) => {
      survey.questionsWithConnectionProblems = { ...survey.questionsWithConnectionProblems, [question.id]: { question: question, ...data } }
      notifySurveyUpdate()
      console.debug('questionsWithConnectionProblems: ', question.id, survey.questionsWithConnectionProblems)
    }

    survey.removeQuestionWithConnectionProblems = (question) => {
      if (survey.questionsWithConnectionProblems[question.id]) {
        survey.questionsWithConnectionProblems = _.omit(survey.questionsWithConnectionProblems, question.id)
        notifySurveyUpdate()
        console.debug('questionsWithConnectionProblems: ', question.id, survey.questionsWithConnectionProblems)
      }
    }

    survey.onStarted.add((survey) => {
      console.debug('survey started with data', content.data)
      survey.data = gewWipData() ? gewWipData().data : content.data
      survey.currentPageNo = gewWipData() ? gewWipData().currentPageNo : 0
      notifySurveyUpdate()
    })

    survey.onComplete.add((survey) => {
      clearWipData()
      console.debug('about to send -> ' + JSON.stringify(survey.data))
      xFetch(actionPaths.submit, {
        method: 'PATCH',
        body: JSON.stringify({ questionnaire_content: survey.data }),
      })
    })

    survey.onValueChanged.add((survey, options) => {
      console.debug(`QUESTION ${options.question.name} changed to: `, JSON.stringify(options.question.value, null, 2))
      keepSessionAlive()
      notifySurveyUpdate()
    })

    survey.onCurrentPageChanged.add((survey, options) => {
      console.debug(`PAGE changed ${options.newCurrentPage}`)
      saveWipData()
      console.debug(`survey data saved in browser: `, JSON.stringify(survey.data, null, 2))
    })

    survey.onLoadChoicesFromServer.add((survey, options) => {
      let q = options.question
      if (q.choicesByUrl.processedUrl) {
        survey.removeWaitingForNetworkQuestion(q.id)
        if (q.choicesByUrl.error) {
          let textError
          try {
            let jsonError = JSON.parse(q.choicesByUrl.error.response)
            textError = jsonError.error || jsonError.msg
          }
          finally {
            textError ||= q.choicesByUrl.error.status
          }
          survey.addQuestionWithConnectionProblems(q, { url: q.choicesByUrl.processedUrl, error: textError })
        }
        else {
          survey.removeQuestionWithConnectionProblems(q)
        }
      }
    })

    survey.onDynamicPanelAdded.add((survey, options) => {
      let q = options.question
      const keyElement = q.templateElements.find(te => te.name == q.keyName && te.getType() == 'text' && te.inputType == 'number')
      // if the key question is a number set a default value for the key
      if (keyElement && keyElement.getType() == 'text' && keyElement.inputType == 'number') {
        let newValue = _.cloneDeep(q.value),
          addedElement = newValue.pop()
        _.defaults(addedElement, { [q.keyName]: _.max([q.panelCount, (_.max(_.map(newValue, q.keyName)) || 0) + 1]) })
        newValue.push(addedElement)
        q.value = newValue
      }
    })

    survey.onMatrixRowAdded.add((survey, options) => {
      let q = options.question
      const keyElement = q.getColumnByName(q.keyName)

      // the question value need to be modified

      // differently from paneldynamic matrixdynamic does not add the new row to the value immediately (only if there is a default row specified)
      let newValue = _.cloneDeep(q.value) || []
      if (newValue.length < q.rowCount) {
        newValue.push({})
      }

      // if the key question is a number set a default value for the key
      if (keyElement && keyElement.cellType == 'text' && keyElement.inputType == 'number') {
        let addedElement = newValue.pop()
        _.defaults(addedElement, { [q.keyName]: _.max([q.rowCount, (_.max(_.map(newValue, q.keyName)) || 0) + 1]) })
        newValue.push(addedElement)
      }

      q.value = newValue
    })

    survey.onTextMarkdown.add(markdownHandler)
  }

  const clearWipData = () => window.localStorage.removeItem(wipDataKey)

  const clearData = () => {
    clearWipData()
    rebuildSurvey()
  }

  return (
    <div className="card questionnaire-content">
      <div className="card-header">
        <div className="row">
          <div className="col-md-12">
            <div className="card-title">
              <h3>
                {questionnaire.name}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <WithSpinner isLoading={isWorking}>
        <div className="card-body">
          {
              window.survey
                ? (
                  <>
                    <ConnectionErrorsHeader detailsEnabled={true} questions={window.survey.questionsWithConnectionProblems} />
                    <Survey
                      model={window.survey}
                    />
                    {
                      window.survey.state == 'running'
                        ? (
                          <div className="float-sm-right mt-2">
                            <ButtonToolbar>
                              <ButtonGroup className="mr-1">
                                <OverlayTrigger overlay={<Tooltip id="tooltip">{surveyLocalization.locales[locale].undoTip}</Tooltip>} placement="top">
                                  <Button disabled={!_isEditable} onClick={() => clearData()} variant="danger">
                                    <i className="fa fa-undo mr-1" />
                                    {surveyLocalization.locales[locale].undo}
                                  </Button>
                                </OverlayTrigger>
                              </ButtonGroup>
                            </ButtonToolbar>
                          </div>
                          )
                        : null
                    }
                  </>
                  )
                : null
            }
        </div>
      </WithSpinner>
    </div>
  )
}

QuestionnaireContent.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  sjsModel: PropTypes.object.isRequired,
  actionPaths: PropTypes.object,
  content: PropTypes.object,
  metaData: PropTypes.object
}

export default QuestionnaireContent
