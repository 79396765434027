import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import Papa from "papaparse"
import JSZip from "jszip"

class LiftUpHistoryStep {
  constructor(data,idx,history) {
    this.history = history
    this.idx = idx
    this.data = data
    _.each(data, (v,k) => this[k] = v)
  }

  get parentSheetId() {
    return this.sheetId.split(".").slice(0,-1).join(".")
  }

  get dataPaths() {
    return _.map(_.flatten(_.compact([this.field,this.fields])), f => [this.sheetId,f].join("."))
  }

  hasAfterFieldDerivedFrom(stepToBeRemoved) {
    switch(stepToBeRemoved.type) {
    case "agg":
      return stepToBeRemoved.name == this.afterField
    case "exp":
      return _.some(stepToBeRemoved.fields, f => _.startsWith(this.afterField, stepToBeRemoved.prefix + f + "_"))
    default:
      console.error(`unexpected liftup history step type ${stepToBeRemoved.type}`)
      return false
    }
  }

  get afterFieldDataPath() {
    if (!this.afterField) return undefined
    let sheetIdArray = this.sheetId.split(".")
    if (sheetIdArray.length == 1) {
      return this.afterField
    } else {
      return [this.parentSheetId, this.afterField].join(".")
    }
  }

  isInUse() {
    return _.some(_.slice(this.history.steps,this.idx+1), laterStep => {
      if (laterStep.sheetId == this.parentSheetId) { // laterStep field may be taken from the field produced by this step
        switch([this.type,laterStep.type].join("")) {
        case "aggagg":
          return laterStep.field == this.name
        case "aggexp":
          return _.some(laterStep.fields, f => f == this.name)
        case "expagg":
          return _.some(this.fields, f => _.startsWith(laterStep.field,`${this.prefix}${f}`))
        case "expexp":
          return _.some(laterStep.fields, lhsf => _.some(this.fields, sf => _.startsWith(lhsf,`${this.prefix}${sf}`)))
        }
      } else {
        return false
      }

    })
  }

}
class LiftUpHistory {
  constructor(initialData = []) {
    this.initialData = initialData
    this.steps = _.map(initialData, (rawStep,i) => new LiftUpHistoryStep(rawStep,i,this))
  }

  addStep(data) {
    this.steps.push(new LiftUpHistoryStep(data))
  }

  replaceStepAt(idx, data) {
    this.steps[idx] = new LiftUpHistoryStep(data)
  }

  removeStepAt(idx) {
    let stepToBeRemoved = this.steps[idx]
    _.each(_.slice(this.steps,idx+1), s => {
      if (s.afterField && s.hasAfterFieldDerivedFrom(stepToBeRemoved)) {
        this.replaceStepAt(s.idx,{...s.data, afterField: null})
      }
    })
    this.steps.splice(idx,1)
  }

  get data() {
    return _.map(this.steps, "data")
  }

  get involvedDataPaths() {
    return _.uniq(_.compact(_.flattenDeep([_.map(this.steps,"dataPaths"),_.map(this.steps,"afterFieldDataPath")])))
  }
}

const exportExcel = ({data, filename, selectedRowIds, personIdName}) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  let outData, wb = { Sheets: {}, SheetNames: [] }

  const addSheet = (sheet) => {
    const
      filteredValues = sheet.values.filter(v=>selectedRowIds[v[personIdName]]),
      fieldNames = _.map(_.filter(sheet.fields, f => !f.hidden), "name")

    wb.Sheets[sheet.excelName] =  XLSX.utils.aoa_to_sheet([fieldNames].concat(filteredValues ? filteredValues.map(row => fieldNames.map(k => row[k])) : []))
    wb.SheetNames.push(sheet.excelName)
  }

  if (_.isPlainObject(data)) {
    addSheet(data)
    filename = data.excelName
  } else {
    _.forEach(data, sheet => addSheet(sheet))
  }

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  outData = new Blob([excelBuffer], {type: fileType})
  FileSaver.saveAs(outData, filename + ".xlsx")
}

const exportCSV = ({data, filename, selectedRowIds, personIdName}) => {
  const fileType = "text/csv;charset=UTF-8"

  const prepareFile = data => {
    const filteredValues = data.values.filter(v=>selectedRowIds[v[personIdName]])
    const fieldNames = _.map(_.filter(data.fields, f => !f.hidden), "name")
    const csv = Papa.unparse(filteredValues, { columns: fieldNames })
    if (csv == null) return
    return new Blob([csv], {type: fileType})
  }

  if (_.isPlainObject(data)) {

    FileSaver.saveAs(prepareFile(data), data.name + ".csv")

  } else {

    const zip = new JSZip()
    _.forEach(data, sheet => {
      zip.file(sheet.name + ".csv", prepareFile(sheet))
    })
    zip.generateAsync({type:"blob"})
      .then(function (blob) {
        FileSaver.saveAs(blob, filename + ".zip")
      })

  }
}

const exportHtml = ({bodyContent, style, filename, title}) => {
  const
    fileType = "text/html",
    fileContent = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <style type="text/css" media="screen">
      ${style}
    </style>
    <title>${title}</title>
  </head>
  <body>
    ${bodyContent}
  </body>
</html>`,
    outData = new Blob([fileContent], {type: fileType})

  FileSaver.saveAs(outData, (filename || _.snakeCase(title)) + ".html")
}

const exportLegendAsCsv = ({columns, data, filename}) => {
  const fileType = "text/csv;charset=UTF-8"
  const prepareFile = data => {
    const csv = Papa.unparse({data: data, fields: columns, delimiter: ";"})
    if (csv == null) return
    return new Blob([csv], {type: fileType})
  }
  FileSaver.saveAs(prepareFile(data), filename + ".csv")
}

function getHeaderRow(sheet) {
  var headers = []
  var range = XLSX.utils.decode_range(sheet["!ref"])
  var C, R = range.s.r /* start in the first row */
  /* walk every column in the range */
  for(C = range.s.c; C <= range.e.c; ++C) {
    var cell = sheet[XLSX.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */

    var hdr = "UNKNOWN " + C // <-- replace with your desired default
    if(cell && cell.t) hdr = XLSX.utils.format_cell(cell)

    headers.push(hdr)
  }
  return headers
}

export { exportHtml, exportExcel, exportCSV, exportLegendAsCsv, getHeaderRow, LiftUpHistory }

