function init(SurveyCore, $) {
  $ = $ || window.$

  /**
   * Create a new JSONEditor
   * @param {string | Element} selector  A query selector id like '#myEditor'
   *                                     or a DOM element to use as container
   * @param {Object} [options]
   * @param {Object} [json]
   * @return {JSONEditor} Returns the created JSONEditor
   */
  function createJSONEditor (selector, options, json) {
    var container = (typeof selector === "string")
      ? document.querySelector(selector)
      : selector

    var editor = new window.JSONEditor(container, options, json)
    container.jsoneditor = editor
    return editor
  }

  var widget = {
    activatedBy: "property",
    name: "jsoneditor",
    title: "Json editor",
    iconName: "",
    widgetIsLoaded: function() {
      return typeof JSONEditor == "function"
    },
    isFit: function(question) {
      return (
        question.getType() === "text" && question.inputType === "json"
      )
    },
    activatedByChanged: function(activatedBy) { },
    isDefaultRender: false,
    htmlTemplate: "<div></div>",
    afterRender: function(question, el) {
      el.style.height = "1000px"
      el.jsoneditor && el.jsoneditor.destroy() // remove previous jsoneditor on the same element (undo_changes needs)

      let jeCurrentValue = function() {
        try {
          return JSON.stringify(editor.get())
        } catch (err) {
          console.warn(err.message)
        }
      }

      let clickHandler = function(e){
        if (!el.contains(e.target)){ // if clicked outside jsoneditor ...
          let currentValue = jeCurrentValue()
          if (currentValue != question.value) { // ... and the value changed from last (not saved) change
            question.value = currentValue // question value need to be updated
          }
        } else { // if clicked inside jsoneditor the event handler must be renewed
          window.addEventListener("click", clickHandler, {once: true})
        }
      }

      let onValueChangedByUserCallback = function() {
        let currentValue = jeCurrentValue()
        if (currentValue && (currentValue != question.value)) {
          window.addEventListener("click", clickHandler, {once: true})
        } else {
          window.removeEventListener("click", clickHandler)
        }
      }

      var onValueChangedCallback = function() {
        question.value && editor.set(JSON.parse(question.value))
      }

      var options = {
        enableTransform: false,
        modes: question.isReadOnly ? ["view"] : ["code", "tree", "text", "view"],
        onChange: onValueChangedByUserCallback,
      }
      var editor = createJSONEditor(el, options, JSON.parse(question.value))

      question.valueChangedCallback = onValueChangedCallback

    },
    willUnmount: function(question, el) {
      question.valueChangedCallback = null
      el.jsoneditor.destroy()
    },
  }

  SurveyCore.CustomWidgetCollection.Instance.add(widget)
}

export default init
