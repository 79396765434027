import React from "react"
import PropTypes from "prop-types"
import DateTime from "react-datetime"
import { Button, Form, Modal } from "react-bootstrap"

export default class AddVersionModal extends React.PureComponent {
  // state = this.props.defaultVersion && {name: this.props.defaultVersion.name, timestamp: moment(this.props.defaultVersion.timestamp)} || { name: '', timestamp: moment() }
  state = this.props.defaultVersion || { name: "", timestamp: moment() }

  static defaultProps = {
    show: false
  }

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    defaultVersion: PropTypes.object,
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmit(this.state)
  }

  handleCancel = () => {
    this.setState(this.props.defaultVersion,this.props.onClose())
  }

  handleNameInputChange = (event) => {
    this.setState({
      name: event.target.value || undefined,
    })
  }

  handleVersionTimestampInputChange = (dt) => {
    this.setState({
      timestamp: dt || undefined
    })
  }

  render(){
    // console.debug("render modal with default "+ JSON.stringify(this.props.defaultVersion))

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onClose}
      >
        <Modal.Header>
          <Modal.Title>Save current content to a new version</Modal.Title>
        </Modal.Header>

        <form onSubmit={this.handleSubmit}>
          <Modal.Body>
            <Form.Group
              controlId="versionName"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={this.handleNameInputChange}
                type="text"
                name="versionName"
                value={this.state.name || ""}
                placeholder="Enter a name for this version"
              />
              <Form.Control.Feedback />
              <Form.Text className="text-muted">A name may be handy to quickly recognize a version</Form.Text>
            </Form.Group>
            <Form.Group
              controlId="versionTimestamp"
            >
              <Form.Label>Timestamp</Form.Label>
              <DateTime value={moment(this.state.timestamp)} locale='it' onChange={this.handleVersionTimestampInputChange} inputProps={{placeholder: "Enter timestamp for this version", name: "versionTimestamp"}}/>
              <Form.Control.Feedback />
              <Form.Text className="text-muted">This is required</Form.Text>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancel}>Close</Button>
            <Button disabled={!this.state.timestamp} type="submit" variant="primary">Save</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
