import React, { useMemo, memo, useState } from 'react'
import PropTypes from 'prop-types'
import * as SurveyCore from 'survey-core'
import { Survey } from 'survey-react-ui'
import * as SjsUtils from '../../sjs_extensions/sjs_utils'
import addWidgets from '../../sjs_extensions/widgets'

function SearchForm({
  onlyReadSearchParams = {},
  handleSearchFormUpdated,
  handleClearSearch,
  canAccessOsr,
}) {
  SurveyCore.StylesManager.applyTheme('bootstrap')

  addWidgets(SurveyCore)

  const initialSearchParams = useMemo(
    () => _.assign({ target: canAccessOsr ? ['cgp', 'osr'] : ['cgp'] }, onlyReadSearchParams)
    , [
      canAccessOsr,
      onlyReadSearchParams,
    ]
  )

  const [searchParams, setSearchParams] = useState(initialSearchParams)

  const survey = useMemo(() => {
    const model = {
      showNavigationButtons: 'none',
      showCompletedPage: false,
      clearInvisibleValues: 'onHidden',
      requiredText: '* ',
      questionTitleTemplate: '{require} {title}',
      showQuestionNumbers: 'off',
      elements: [
        {
          type: 'text',
          name: 'name',
          readOnly: _.has(onlyReadSearchParams, 'name'),
          title: 'Name',
        },
        {
          type: 'text',
          name: 'surname',
          startWithNewLine: false,
          readOnly: _.has(onlyReadSearchParams, 'surname'),
          title: 'Surname',
        },
        {
          type: 'dropdown',
          name: 'sex',
          startWithNewLine: false,
          title: 'Sex',
          readOnly: _.has(onlyReadSearchParams, 'sex'),
          choices: [
            {
              value: 'm',
              text: 'male',
            },
            {
              value: 'f',
              text: 'female',
            },
          ],
        },
        {
          type: 'text',
          name: 'dob',
          title: 'Date of Birth',
          readOnly: _.has(onlyReadSearchParams, 'dob'),
          inputType: 'date',
        },
        {
          type: 'text',
          name: 'taxcode',
          startWithNewLine: false,
          readOnly: _.has(onlyReadSearchParams, 'taxcode'),
          title: 'Fiscal Code',
        },
        canAccessOsr
        && {
          type: 'checkbox',
          name: 'target',
          startWithNewLine: false,
          title: 'Search target',
          colCount: 2,
          isRequired: true,
          readOnly: _.has(onlyReadSearchParams, 'target'),
          choices: [
            {
              value: 'cgp',
              text: 'CGP',
            },
            {
              value: 'osr',
              text: 'OSR',
            },
          ],
        },
      ],
    }

    SurveyCore.StylesManager.applyTheme('bootstrap')
    addWidgets(SurveyCore)

    const survey = new SurveyCore.Model(model)

    const debounceHandleSearchFormUpdated = _.debounce(handleSearchFormUpdated, 2000)

    survey.onValueChanged.add((survey) => {
      setSearchParams(survey.data)
      debounceHandleSearchFormUpdated(survey.data)
    })

    return survey
  }, [])

  const clearSearch = () => {
    setSearchParams(initialSearchParams)
    handleClearSearch()
  }

  return (
    <div className="card mb-4">
      <div className="card-body">
        <Survey
          css={SjsUtils.getCustomizedBootstrapCss()}
          data={searchParams}
          model={survey}
        />

        <div className="clearfix" />
        <div className="row">
          <div className="col-12">
            <button className="btn btn-link mr-2 float-right" onClick={clearSearch} type="button">Clear Form</button>
          </div>
        </div>
      </div>
    </div>
  )
}

SearchForm.propTypes = {
  onlyReadSearchParams: PropTypes.object,
  handleSearchFormUpdated: PropTypes.func.isRequired,
  handleClearSearch: PropTypes.func.isRequired,
  canAccessOsr: PropTypes.bool.isRequired,
}

export default memo(SearchForm)
