import React from "react"
import PropTypes from "prop-types"
import { useTable, usePagination, useSortBy, useFilters, useRowSelect } from "react-table"
import BTable from "react-bootstrap/Table"
import {DropdownButton,Dropdown} from "react-bootstrap"
import styled from "styled-components"
import LoadingOverlay from "react-loading-overlay"
LoadingOverlay.propTypes = undefined // workaround to remove warning https://github.com/derrickpelletier/react-loading-overlay/pull/57#issuecomment-1054194254

// Create a default prop getter
const defaultCustomPropGetter = () => ({})

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, setFilter },
}) {
  return (
    <input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
  table {
    tbody {
      font-family: monaco
    }
  }
`

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

function Datatable({
  columns,
  data = [],
  getCustomCellProps = defaultCustomPropGetter,
  getCustomColumnProps = defaultCustomPropGetter,
  getCustomHeaderProps = defaultCustomPropGetter,
  getCustomRowProps = defaultCustomPropGetter,
  initialSelectedRowIds,
  initialHiddenColumns,
  datatableState,
  loading,
  rowId,
  handleDatatableStateChange,
  disableRowSelection
}) {

  const rowSelectionHooks = [
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) =>
            <IndeterminateCheckbox disabled={disableRowSelection} {...getToggleAllRowsSelectedProps()} />
          ,
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) =>
            <IndeterminateCheckbox disabled={disableRowSelection} {...row.getToggleRowSelectedProps()} />
        },
        ...columns,
      ])
    }
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds, sortBy }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: datatableState?.pageIndex || 0,
        pageSize: datatableState?.pageSize || 10,
        selectedRowIds: initialSelectedRowIds || {},
        sortBy: datatableState?.sortBy || [],
        hiddenColumns: initialHiddenColumns || []
      }, // Pass our hoisted table state
      autoResetPage: false,
      autoResetSortBy: false,
      disableFilters: true, // remove to enable filtering
      autoResetFilters: false,
      defaultColumn: React.useMemo(
        () => ({
          Filter: DefaultColumnFilter,
        }),
        []
      ),
      getRowId: rowId ? React.useCallback(row => row[rowId], []) : undefined,
      isMultiSortEvent: e => e.metaKey, // change default multisort key to cmd/ctrl instead of shift,
    },
    useFilters,
    useSortBy,
    usePagination,
    ...(rowId ? rowSelectionHooks : [])
  )

  React.useEffect(() => {
    if (data.length) {
      handleDatatableStateChange({pageIndex, pageSize, selectedRowIds, sortBy})
    }
  }, [pageIndex, pageSize, selectedRowIds, sortBy])

  return (
    <Styles>
      <LoadingOverlay
        active={loading}
        text='Loading ...'
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0, 0, 0, 0.3)"
          })
        }}
      >
        <BTable responsive hover bordered size="sm" {...getTableProps()}>
          <thead className="thead-light">
            {headerGroups.map(headerGroup => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...column.getHeaderProps([
                      {
                        className: `${column.className || ""} align-bottom`,
                        style: column.style,
                      },
                      getCustomColumnProps(column),
                      getCustomHeaderProps(column),
                    ])}
                  >
                    <div {...column.getSortByToggleProps()} >
                      {column.render("Header")}
                      <span> {column.isSorted ? column.isSortedDesc ? "▼" : "▲" : ""}</span>
                    </div>
                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              _.isEmpty(page) ?
                <tr>
                  <td colSpan="10000" className="lead text-center">
                    no data to show
                  </td>
                </tr>
                :
                page.map((row, i) => {
                  prepareRow(row)
                  return (
                    // Merge user row props in
                    // eslint-disable-next-line react/jsx-key
                    <tr {...row.getRowProps(getCustomRowProps(row)) } >
                      {row.cells.map(cell =>
                        // eslint-disable-next-line react/jsx-key
                        <td
                          // Return an array of prop objects and react-table will merge them appropriately
                          {...cell.getCellProps([
                            {
                              className: `${cell.className || ""} text-nowrap`,
                              style: cell.column.style,
                            },
                            getCustomColumnProps(cell.column),
                            getCustomCellProps(cell),
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      )}
                    </tr>
                  )
                })
            }
          </tbody>
        </BTable>
        <div className="bg-light p-2 mb-2 d-flex justify-content-between">
          <p className="mb-0">
            {
              page.length < data.length ?
                <span>
                  Page: {pageIndex+1}/{pageOptions.length}
                  <br />
                  Results: {page.length}/{data.length}
                </span>
                :
                <span>
                  Results: {data.length}
                </span>
            }
            {
              rowId ?
                <span><br />{`Selected: ${_.intersection(_.map(data,rowId) , _.keys(selectedRowIds)).length}`}</span>
                :
                null
            }
          </p>
          <div>
            {
              page.length < data.length ?
                <ul className="pagination pagination-sm mb-0">
                  <li className={"page-item" + (canPreviousPage ? "" : " disabled")}>
                    <a className="page-link" onClick={() => gotoPage(0)}>
                      first
                    </a>
                  </li>
                  <li className={"page-item" + (canPreviousPage ? "" : " disabled")}>
                    <a className="page-link" onClick={() => previousPage()}>
                      previous
                    </a>
                  </li>
                  <li className={"page-item" + (canNextPage ? "" : " disabled")}>
                    <a className="page-link" onClick={() => nextPage()}>
                      next
                    </a>
                  </li>
                  <li className={"page-item" + (canNextPage ? "" : " disabled")}>
                    <a className="page-link" onClick={() => gotoPage(pageCount - 1)}>
                      last
                    </a>
                  </li>
                </ul>
                :
                <ul></ul>
            }
          </div>
          <div>
            <DropdownButton title={`${pageSize} per page`} size="sm" variant="outline-secondary">
              {[2, 5, 10, 20, 30, 40, 50].map(pageSize =>
                <Dropdown.Item
                  key={pageSize}
                  onClick={e => {
                    setPageSize(pageSize)
                    gotoPage(0)
                  }}
                >
                  {pageSize} per page
                </Dropdown.Item>
              )}
            </DropdownButton>
          </div>
        </div>
      </LoadingOverlay>
    </Styles>
  )
}

Datatable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  getCustomCellProps: PropTypes.func,
  getCustomColumnProps: PropTypes.func,
  getCustomHeaderProps: PropTypes.func,
  getCustomRowProps: PropTypes.func,
  handleDatatableStateChange: PropTypes.func.isRequired,
  datatableState: PropTypes.object,
  initialSelectedRowIds: PropTypes.object,
  initialHiddenColumns: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  disableRowSelection: PropTypes.bool,
  rowId: PropTypes.string
}

const memoizedDatatable = React.memo(Datatable)
memoizedDatatable.displayName = "Datatable"

export default memoizedDatatable
