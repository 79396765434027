import React from "react"
import PropTypes from "prop-types"
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap"
import {CopyToClipboard} from "react-copy-to-clipboard"
import {usertimestampedBy} from "../utils"
import { bootstrapSelectStyle } from "../utils"
import Select from "react-select"

export default class Sample extends React.Component {

  static propTypes = {
    onDelete: PropTypes.func,
    onHandleRemoveForm: PropTypes.func,
    onHandleAddForm: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    forms: PropTypes.array.isRequired,
    sample: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      form_ids: props.sample.form_ids
    }
  }

  handleFormsChange = (val,event) => {
    switch(event.action) {
      case 'select-option':
        this.props.onHandleAddForm(event.option.value)
        break;
      case 'remove-value':
        this.props.onHandleRemoveForm(event.removedValue.value)
        break;
    }
  }

  render() {

    const clipboardTooltip = (
      <Tooltip id="clipboardTooltip">
        copy sample code to clipboard
      </Tooltip>
    )

    const deleteSampleTooltip = (
      <Tooltip id="deleteSampleTooltip">
        delete this sample
      </Tooltip>
    )

    const formOptions = this.props.forms.map((c) => {
      return {
        value: c.id.toString(),
        label: c.name,
      }
    })

    return (
      <div className="small-box bg-yellow">
        <div className="inner">
          <h5 className='id'>{this.props.sample.code} <small>{moment(this.props.sample.date).format()}</small></h5>
          <Select
            styles={bootstrapSelectStyle}
            isMulti={true}
            isClearable={true}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            value={formOptions.filter(o => _.includes(this.props.sample.form_ids, o.value))}
            onChange={this.handleFormsChange}
            options={formOptions}
            placeholder="forms that will use this sample ..."
          />
          <div className="icon">
            <i className='fa fa-flask'></i>
          </div>
        </div>
        <div className="small-box-footer text-left pl-1">
          <small>
            {usertimestampedBy(this.props.sample.created_at,this.props.sample.creator,"created")}
          </small>
          <div className="text-right pr-1">
            <div className="btn-group ">
              <OverlayTrigger placement="top" overlay={clipboardTooltip}>
                <Button disabled={this.props.loading} variant="default" size='sm'>
                  <CopyToClipboard text={this.props.sample.code}>
                    <i className="fa fa-clipboard" />
                  </CopyToClipboard>
                </Button>
              </OverlayTrigger>
              {
                this.props.onDelete &&
                  <OverlayTrigger placement="top" overlay={deleteSampleTooltip}>
                    <Button disabled={this.props.loading} variant="danger" size='sm' onClick={() => this.props.onDelete(this.props.sample.code)}>
                      <i className="fa fa-trash" />
                    </Button>
                  </OverlayTrigger>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
