import * as React from 'react'
import { ReactQuestionFactory, SurveyQuestionExpression } from 'survey-react-ui'

class CgpExpression extends SurveyQuestionExpression {
  renderElement() {
    return (
      <>
        <div
          className="sd-input sd-input--readonly"
          disabled={true}
          id={this.question.inputId}
          style={{
            textWrap: 'wrap',
            paddingRight: '30px',
            alignContent: 'center'
          }}
        >
          {this.question.formatedValue}
        </div>
        <i
          className="fa fa-calculator float-right"
          style={{
            display: 'inline-block',
            marginTop: '-24px',
            marginRight: '10px',
            opacity: '0.4',
          }}
        />
      </>
    )
  }
}

ReactQuestionFactory.Instance.registerQuestion(
  'sv-cgp-expression',
  function (props) {
    return React.createElement(CgpExpression, props)
  }
)
