import React from 'react'
import PropTypes from 'prop-types'
import { HelpPopper, md2Html } from './utils'

function QuestionHelper({ item }) {
  if (!item?.data?.text) {
    console.warn('QuestionHelper: empty data.text field for item', item)
    return
  }
  return (
    <HelpPopper options={{ placement: 'top' }}>
      <div dangerouslySetInnerHTML={{ __html: md2Html(item.data.text) }} />
    </HelpPopper>
  )
}

QuestionHelper.propTypes = {
  item: PropTypes.object.isRequired,
}

export default QuestionHelper
