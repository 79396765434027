import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import SearchForm from './search_form'
import SearchResults from './search_results'
import { xFetch } from '../utils'

export default function SearchPersonWidget({
  onlyReadSearchParams,
  canAccessOsr,
  actionCell,
}) {
  // console.debug('render SearchPersonWidget')

  const [searchResults, setSearchResults] = useState()
  const [loading, setLoading] = useState(false)

  let previousRequestController

  const handleSearchFormUpdated = useCallback((params) => {
    previousRequestController && previousRequestController.abort()
    setLoading(true)
    previousRequestController = new AbortController()
    xFetch(Routes.search_people_path(params), { signal: previousRequestController.signal })
      .then((data) => {
        setSearchResults(data)
        previousRequestController = undefined
        setLoading(false)
      })
  }, [])

  const handleClearSearch = useCallback(() => {
    setSearchResults()
  }, [])

  return (
    <>
      <SearchForm
        canAccessOsr={canAccessOsr}
        handleClearSearch={handleClearSearch}
        handleSearchFormUpdated={handleSearchFormUpdated}
        onlyReadSearchParams={onlyReadSearchParams}
      />
      <SearchResults
        actionCell={actionCell}
        loading={loading}
        searchResults={searchResults}
        showCgpResults={_.includes(onlyReadSearchParams?.target, 'cgp')}
        showOsrResults={_.includes(onlyReadSearchParams?.target, 'osr')}
        willAdd={false}
      />
    </>
  )
}

SearchPersonWidget.propTypes = {
  onlyReadSearchParams: PropTypes.object,
  canAccessOsr: PropTypes.bool.isRequired,
  actionCell: PropTypes.func.isRequired,
}
