import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import ResourcesLister from './resources_lister/resources_lister'
import AddNamedPersonWidget from './add_named_person_widget'
import AddPersonWidget from './add_person_widget'
import RemoveFromCohortDialog from './remove_from_cohort_dialog'

function CohortMembersLister({
  cohort,
  canAddRemoveMembers,
  itemsPath,
  attributes = [],
  rowId,
  canDownload,
  anonym = true,
  personSjsModel,
  canAccessOsr,
}) {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false)
  const [memberToBeRemoved, setMemberToBeRemoved] = useState()
  const [lastMemberAdded, setLastMemberAdded] = useState()

  const toggle = () =>
    setShowAddMemberModal(!showAddMemberModal)

  const removeMember = function (e, person) {
    e.stopPropagation()
    setMemberToBeRemoved(person)
  }

  const displayPerson = function (person) {
    return person.fullname || person.id
  }

  const addMember = (person) => {
    setShowAddMemberModal(false)
    setLastMemberAdded(person.id)
    toastr.success(`${displayPerson(person)} was added to this cohort`)
  }

  const actionCell = member => canAddRemoveMembers
    ? <Button block onClick={e => removeMember(e, member)} size="xs" variant="danger">remove</Button>
    : null

  return (
    <>
      {
      cohort.member_ids.length
        ? (
          <ResourcesLister
            actionCell={actionCell}
            attributes={attributes}
            downloadEnabled={canDownload}
            itemsPath={itemsPath}
            key={lastMemberAdded}
            rowId={rowId}
          />
          )
        : (
          <p className="lead">
            No members yet
          </p>
          )
    }
      {
      canAddRemoveMembers
        ? (
          <>
            <Button onClick={toggle} variant="primary">
              <i className="fas fa-plus-circle mr-2" />
              Add Member
            </Button>
            <Modal
              dialogClassName="modal-90w"
              onHide={toggle}
              show={showAddMemberModal}
            >
              <Modal.Header>
                <Modal.Title>{`Add Member to Cohort ${cohort.name}`}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {
                anonym
                  ? (
                    <AddPersonWidget
                      afterAddPerson={addMember}
                      availableCohorts={[cohort]}
                      initialCohortIds={[cohort.id]}
                      personSjsModel={personSjsModel}
                    />
                    )
                  : (
                    <AddNamedPersonWidget
                      afterAddPerson={addMember}
                      availableCohorts={[cohort]}
                      canAccessOsr={canAccessOsr}
                      initialCohortIds={[cohort.id]}
                      personSjsModel={personSjsModel}
                    />
                    )
              }
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={toggle}>Close</Button>
              </Modal.Footer>
            </Modal>
            {
            memberToBeRemoved
              ? (
                <RemoveFromCohortDialog
                  cid={cohort.id}
                  handleClose={setMemberToBeRemoved}
                  pid={memberToBeRemoved.id}
                />
                )
              : null
          }
          </>
          )
        : null
    }
    </>
  )
}

CohortMembersLister.propTypes = {
  attributes: PropTypes.object,
  cohort: PropTypes.object.isRequired,
  personSjsModel: PropTypes.object.isRequired,
  itemsPath: PropTypes.string.isRequired,
  canAccessOsr: PropTypes.bool.isRequired,
  canAddRemoveMembers: PropTypes.bool.isRequired,
  anonym: PropTypes.bool,
  rowId: PropTypes.string,
}

export default CohortMembersLister
