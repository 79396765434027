import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { Button, Modal, Alert } from 'react-bootstrap'
import { xFetch, keepSessionAlive, keepSessionAliveThrottleTimeInSecs } from "./utils"

import styled from "styled-components"

const Styles = styled.div`
  .alert {
    margin-bottom: 0px;
    text-align: center;
    a {
      text-decoration: unset;
    }
  }
`

export default function SessionWarnWidget({
}) {

  const [showModal, setShowModal] = useState(false)
  const [remainingTime, setRemainingTime] = useState()

  const getExpireAt = () => moment.unix(parseInt(getCookie('session-expires-at')))

  const refreshSession = () => {
    setShowModal(false)
    setRemainingTime()
    keepSessionAlive()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getExpireAt().diff(moment(), 'seconds'))
      if (remainingTime < keepSessionAliveThrottleTimeInSecs) {
        setShowModal(true)
        $('body .wrapper')[0].classList.add("blurred");
        $('body footer')[0].classList.add("blurred");
      } else {
        setShowModal(false)
        $('body .wrapper')[0].classList.remove("blurred");
        $('body footer')[0].classList.remove("blurred");
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [remainingTime, getExpireAt()])



  return <Modal
    show={showModal}
    style={{marginTop: "200px"}}
  >
    <Styles>
      <Alert variant="warning">
        <Alert.Heading>
          <i className="fa fa-exclamation-triangle mr-2" />
          {
            remainingTime <= 0
            ? "Session expired"
            : `Session will expire in ${remainingTime} seconds`
          }
        </Alert.Heading>
        {
          remainingTime <= 0
          ?
            <span>
              You need to
              <a className="" onClick={() => window.location.reload()}> sign in </a>
              again to continue.
            </span>
          : <Button onClick={refreshSession} variant="primary" block>
              I am still here!
            </Button>
        }
      </Alert>
    </Styles>
  </Modal>
}

SessionWarnWidget.propTypes = {
}

SessionWarnWidget.defaultProps = {
}
