import React from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"

export default class NewSample extends React.Component {

  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      date: moment().format(),
      code: ""
    }
  }

  handleChange = (e) => {
    let value, propName = e.target.name
    if (e.target.name == "form_ids") {
      let options = e.target.options
      value = []
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push(options[i].value)
        }
      }
      value.sort()
    } else {
      value = e.target.value
    }
    this.setState({
      [propName]: value
    })
  }


  render() {
    return (
      <div className="small-box bg-yellow">
        <div className="inner">
          <div className="form-group">
            <input className="form-control" type="text" size="50" autoFocus name="code" placeholder="sample code ..." value={this.state.code} onChange={this.handleChange} />
          </div>
          <div className="form-group">
            <input className="form-control" type="date" name="date" value={this.state.date} onChange={this.handleChange} />
          </div>
        </div>
        <div className="small-box-footer">
          <div className="text-right pr-1">
            <div className="btn-group">
              <Button variant='link'  size='sm' onClick={this.props.onCancel}>cancel</Button>
              <Button disabled={this.props.loading} variant='primary' size='sm' type="submit" onClick={() => this.props.onSave(this.state)}>save</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
