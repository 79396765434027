import React from "react"
import PropTypes from "prop-types"
import Relative from "./relative"
import AddNamedPersonWidget from "../add_named_person_widget"
import AddPersonWidget from "../add_person_widget"
import { Modal, Button } from "react-bootstrap"
import { xFetch } from "../utils"
import LoadingOverlay from "react-loading-overlay"
LoadingOverlay.propTypes = undefined // workaround to remove warning https://github.com/derrickpelletier/react-loading-overlay/pull/57#issuecomment-1054194254

export default class RelativesEditor extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      family: {},
      linkingData: null,
      loading: false
    }
  }

  static defaultProps = {
    anonym: true,
    onlyRead: true
  }

  static propTypes = {
    person: PropTypes.object.isRequired,
    onlyRead: PropTypes.bool,
    newRelativeSjsModel: PropTypes.object.isRequired,
    canAccessOsr: PropTypes.bool.isRequired,
    availableCohorts: PropTypes.array,
    anonym: PropTypes.bool
  }

  _reloadFamily() {
    this.setState({
      loading: true,
      linkingData: null,
    }, () =>
      xFetch(Routes.family_relatives_person_path(this.props.person.id))
        .then(data => this.setState({family: data}))
        .catch(error => {
          toastr.error(_.join(error.msgs, "<br>"), "Cannot load relatives")
        })
        .finally(() => this.setState({
          loading: false
        }))
    )
  }

  componentDidMount() {
    this._reloadFamily()
  }

  _partnerById(id) {
    return this.state.family.partners.find((p) => {return p.id == id} )
  }

  _partnerByChild(child) {
    if (this.props.person.sex === "male") return this._partnerById(child.mother_id)
    if (this.props.person.sex === "female") return this._partnerById(child.father_id)
    throw "person sex is needed to group child by partner"
  }

  _abortlinkingRelative = () => {
    this.setState({linkingData: null})
  }

  _startlinkingRelative = (role,partner) => {
    this.setState({linkingData: {role: role, partner_id: partner && partner.id}})
  }

  _finishlinkingRelative = () => {
    this.setState({linkingData: null})
  }

  _linkRelative = (relativeId) =>
    this.setState({
      loading: true,
    }, () => xFetch(Routes.link_relatives_person_path(this.props.person.id), {
      method: "PATCH",
      body: JSON.stringify({ linking_data: { relative_id: relativeId, ...this.state.linkingData } }),
    })
      .then( () => {
        toastr.success(`${this.state.linkingData.role} correctly linked`)
      })
      .catch(error => {
        toastr.error(_.join(error.msgs, "<br>"), "Relative was not linked")
      })
      .finally(() => this._reloadFamily())
    )

  _unlinkRelative = (relativeId,role) =>
    this.setState({
      loading: true,
    }, () => xFetch(Routes.unlink_relatives_person_path(this.props.person.id), {
      method: "PATCH",
      body: JSON.stringify({linking_data: {relative_id: relativeId, role: role}}),
    })
      .then( () => {
        toastr.success(`${role} correctly unlinked`)
      })
      .catch(error => {
        toastr.error(_.join(error.msgs, "<br>"), "Relative was not unlinked")
      })
      .finally(() => this._reloadFamily())
    )

  render() {
    const f = this.state.family
    const groupedChildren = _.groupBy(f.children, (c) => { return this._partnerByChild(c) && this._partnerByChild(c).id })
    groupedChildren[undefined] = groupedChildren[undefined] || []

    const relative = (role, person) => {
      let relative = person || f[role]
      return relative ? <Relative relative={relative} role={role} onUnlink={this._unlinkRelative} onlyRead={this.props.onlyRead}/> : !this.props.onlyRead && linkButton(role)
    }

    const linkButton = (role) => {
      return <button className="btn btn-outline-primary btn-block" onClick={() => {this._startlinkingRelative(role)}}>link {_.startCase(role)}</button>
    }

    const linkButtonWith = (role,partner) => {
      return <button className="btn btn-outline-primary btn-block" onClick={() => {this._startlinkingRelative(role,partner)}}>{`link ${role} with ${partner ? partner.fullname : "unknown partner"}`}</button>
    }

    const AddRelativeModal = () => {
      return (
        <Modal
          show={!_.isNull(this.state.linkingData)}
          onHide={this._abortlinkingRelative}
          dialogClassName="modal-90w"
        >
          <Modal.Header>
            <Modal.Title>Link {_.startCase(this.state.linkingData.role)}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {
              this.props.anonym ?
                <AddPersonWidget
                  personSjsModel={this.props.newRelativeSjsModel}
                  afterAddPerson={newPerson => this._linkRelative(newPerson.id)}
                  availableCohorts={this.props.availableCohorts}
                  isRelative={true}
                />
                :
                <AddNamedPersonWidget
                  personSjsModel={this.props.newRelativeSjsModel}
                  canAccessOsr={this.props.canAccessOsr}
                  afterAddPerson={newPerson => this._linkRelative(newPerson.id)}
                  availableCohorts={this.props.availableCohorts}
                  isRelative={true}
                />
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this._abortlinkingRelative}>Close</Button>
          </Modal.Footer>
        </Modal>
      )
    }

    {
      return (
        <LoadingOverlay
          className="container-fluid p-0"
          active={this.state.loading}
          text='Loading ...'
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.3)"
            })
          }}
        >
          {this.state.linkingData && <AddRelativeModal />}
          {!_.isEmpty(f) &&
            <div>
              <div className='row relatives'>
                <div className='col-12 col-md-6'>
                  <div className='relatives_group'>

                    <h3>paternal lineage</h3>

                    {
                      f.father && (
                        <div className="row small-boxes-list">
                          <div className='col-12 col-lg-6'>
                            { relative("paternal_grandfather") }
                          </div>
                          <div className='col-12 col-lg-6'>
                            { relative("paternal_grandmother") }
                          </div>
                        </div>
                      )
                    }


                    <div className="row small-boxes-list">
                      <div className='col-12 col-lg-6 offset-lg-3'>
                        { relative("father") }
                      </div>
                    </div>

                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='relatives_group'>

                    <h3>maternal lineage</h3>

                    {
                      f.mother && (
                        <div className="row small-boxes-list">
                          <div className='col-12 col-lg-6'>
                            { relative("maternal_grandfather") }
                          </div>
                          <div className='col-12 col-lg-6'>
                            { relative("maternal_grandmother") }
                          </div>
                        </div>
                      )
                    }

                    <div className="row small-boxes-list">
                      <div className='col-12 col-lg-6 offset-lg-3'>
                        { relative("mother") }
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className='row relatives'>
                {
                  (f.mother || f.father) && (
                    <div className='col-12'>
                      <div className="relatives_group">
                        <h3>siblings</h3>
                        <div className="row small-boxes-list">
                          {f.siblings.map(function(p, index){
                            return (
                              <div className='col-12 col-md-6 col-lg-3' key={p.id}>
                                { relative("sibling",p) }
                              </div>
                            )
                          })}
                          {f.paternal_half_siblings.map(function(p, index){
                            return (
                              <div className='col-12 col-md-6 col-lg-3' key={p.id}>
                                { relative("paternal_half_sibling",p) }
                              </div>
                            )
                          })}
                          {f.maternal_half_siblings.map(function(p, index){
                            return (
                              <div className='col-12 col-md-6 col-lg-3' key={p.id}>
                                { relative("maternal_half_sibling",p) }
                              </div>
                            )
                          })}

                          <div className='col-12 col-md-6 col-lg-3'>
                            {
                              !this.props.onlyRead &&
                              <React.Fragment>
                                {
                                  f.father && f.mother && linkButton("sibling")
                                }
                                {
                                  f.father && linkButton("paternal_half_sibling")
                                }
                                {
                                  f.mother && linkButton("maternal_half_sibling")
                                }
                              </React.Fragment>
                            }

                          </div>
                        </div>

                      </div>
                    </div>
                  )
                }

              </div>
              <div className="row relatives">
                <div className='col-12'>
                  <div className="relatives_group">
                    {
                      _.isEmpty(groupedChildren)
                        ? (
                          <div>
                            <h3>offspring</h3>
                            <div className='col-12 col-md-6 col-lg-3' key='missingChild'>
                              { linkButton("child") }
                            </div>
                          </div>
                        )
                        : _.map(groupedChildren, (childrens,partnerId) => {
                          let partner = this._partnerById(partnerId)
                          return (

                            <div key={partnerId}>
                              <h3>{partner ? `offspring with ${partner.fullname}` : "offspring with unknown partner"}</h3>
                              <div className="row small-boxes-list">
                                {childrens.map(function(p, index){
                                  return (
                                    <div className='col-12 col-md-6 col-lg-3' key={p.id}>
                                      { relative("child",p) }
                                    </div>
                                  )
                                })}
                                {
                                  !this.props.onlyRead &&
                                  <div className='col-12 col-md-6 col-lg-3' key='missingChild'>
                                    { linkButtonWith("child", partner) }
                                  </div>
                                }
                              </div>
                            </div>
                          )
                        })

                    }
                  </div>
                </div>

              </div>
            </div>
          }
        </LoadingOverlay>
      )
    }

  }
}

