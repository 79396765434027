import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as SurveyCore from 'survey-core'
import * as SjsUtils from '../../sjs_extensions/sjs_utils'
import { Survey } from 'survey-react-ui'
import Versioner from './versioner'
import Navigator from './navigator'
import ConnectionErrorsHeader from './connection_errors_header'
import RawJsonDataViewer from './raw_json_data_viewer'
import { markdownHandler, Userstamp, xFetch, keepSessionAlive, WithSpinner } from '../utils'
import { diff } from 'jsondiffpatch/with-text-diffs'
import { Alert, Tooltip, OverlayTrigger, ButtonGroup, ButtonToolbar, Modal, Button } from 'react-bootstrap'

function PageErrorsHeader({
  pagesWithErrors = [],
  handleDismiss,
  show,
}) {
  if (show && pagesWithErrors.length) {
    return (
      <Alert dismissible onClose={handleDismiss} variant="danger">
        <Alert.Heading>Pages with errors:</Alert.Heading>
        <ul>
          {pagesWithErrors.map((page, i) => <li key={i}>{page}</li>)}
        </ul>
      </Alert>
    )
  }
  return null
}

let tmpWaitingForNetworkQuestions = []

function CollectorContent({
  content,
  pageNumber,
  actionPaths,
  collector,
  metaData,
}) {
  const [initialData, setInitialData] = useState(content.data)
  const [presignedPostData, setPresignedPostData] = useState()
  const [brandNewFiles, setBrandNewFiles] = useState([])
  const [toBeDeletedFiles, setToBeDeletedFiles] = useState([])
  const [currentPageNo, setCurrentPageNo] = useState(pageNumber ? parseInt(pageNumber) - 1 : 0)
  const [pagesWithErrors, setPagesWithErrors] = useState([])
  const [savedVersions, setSavedVersions] = useState(content.saved_versions)
  const [selectedVersionId, setSelectedVersionId] = useState(content.id)
  const [showChanges, setShowChanges] = useState(false)
  const [showPageErrorsHeader, setShowPageErrorsHeader] = useState(false)
  const [isWorking, setIsWorking] = useState(true)
  const [changes, setChanges] = useState()
  const [lastForcedUpdate, setLastForcedUpdate] = useState(Date.now)

  const isNewContent = !content.id
  const isChanged = !_.isEmpty(changes)
  const isSavedAndChanged = !isNewContent && isChanged
  const _isCurrentVersion = selectedVersionId == content.id
  const _selectedVersion = savedVersions?.find(v => v.id == selectedVersionId)

  const _NoConnectionProblems = () => {
    return _.isEmpty(window.survey?.questionsWithConnectionProblems)
  }
  const _isEditable = !!actionPaths.save && _isCurrentVersion && _NoConnectionProblems()

  const notifySurveyUpdate = () => setLastForcedUpdate(Date.now)

  const rebuildSurvey = () => {
    setPagesWithErrors([])
    setIsWorking(true)
    setChanges()
    window.survey = buildSurvey()
    window.survey.runConditions() // force runConditions because some of them may not be run until survey.setupIsFinished become true
    notifySurveyUpdate()
  }

  useEffect(() => {
    SjsUtils.setupForForm(SurveyCore, tmpWaitingForNetworkQuestions)
    actionPaths.presignedPost && xFetch(actionPaths.presignedPost).then(body => setPresignedPostData(body))
  }, [])

  useEffect(() => {
    rebuildSurvey()
  }, [initialData, presignedPostData])

  useEffect(() => {
    updateAddressBar()
  }, [currentPageNo])

  useEffect(() => {
    if (isWorking) return
    console.debug('checking errors ...')
    window.survey.clearIncorrectValues()
    const newPagesWithErrors = _.reduce(window.survey.pages, (accumulator, page) => {
      if (!page.validate(showPageErrorsHeader, false)) {
        return _.union(accumulator, [page.title || page.name])
      }
      else {
        return accumulator
      }
    }, [])
    setPagesWithErrors(newPagesWithErrors)
    setShowPageErrorsHeader(_.isEmpty(pagesWithErrors) ? false : showPageErrorsHeader)
  }, [changes, isWorking, showPageErrorsHeader])

  useEffect(() => updateLoadingState(), [lastForcedUpdate])

  const updateLoadingState = _.debounce(() => {
    console.debug('updateLoadingState')
    if (window.survey.waitingForNetworkQuestions.length > 0) {
      setIsWorking(true)
    }
    else {
      setIsWorking(false)
      setChanges(_calCchanges())
      window.survey.mode = _isEditable ? 'edit' : 'display'
      window.survey.clearIncorrectValues()
    }
  }, 500)

  const buildSurvey = () => {
    const baseSjsModel = {
      clearInvisibleValues: 'onHiddenContainer',
      requiredText: '* ',
      showNavigationButtons: 'none',
      showPageTitles: false,
      showCompletedPage: false,
      focusFirstQuestionAutomatic: false,
      showQuestionNumbers: 'off',
      showTitle: false,
      storeOthersAsComment: false,
      widthMode: 'responsive',
      questionDescriptionLocation: 'underInput',
    }
    const mergedSjsModel = _.merge(baseSjsModel, collector.model)

    console.debug('Creating survey...')
    let survey = new SurveyCore.Model(mergedSjsModel)

    console.debug('Setting up survey...')
    setupSurvey(survey)
    survey.setupIsFinished = true

    return survey
  }

  const setupSurvey = (survey) => {
    survey.applyTheme(SjsUtils.FlatLightPanelless)
    survey.css = SjsUtils.customizeCssClasses(SurveyCore.defaultV2Css)

    survey.questionsWithConnectionProblems = {}

    // set variables for metadata
    _.forOwn(metaData, (v, k) => survey.setVariable(`meta_${k}`, v))

    // after survey is built waitingForNetworkQuestions is initialized with elements collected by SurveyCore.ChoicesRestfull.onBeforeSendRequest callback which run before
    survey.waitingForNetworkQuestions = tmpWaitingForNetworkQuestions
    tmpWaitingForNetworkQuestions = []

    survey.addWaitingForNetworkQuestion = (id) => {
      survey.waitingForNetworkQuestions.push(id)
      notifySurveyUpdate()
      console.debug('addWaitingForNetworkQuestion: ', id, survey.waitingForNetworkQuestions)
    }

    survey.removeWaitingForNetworkQuestion = (id) => {
      survey.waitingForNetworkQuestions = _.without(survey.waitingForNetworkQuestions, id)
      notifySurveyUpdate()
      console.debug('removeWaitingForNetworkQuestion: ', id, survey.waitingForNetworkQuestions)
    }

    survey.addQuestionWithConnectionProblems = (question, data) => {
      survey.questionsWithConnectionProblems = { ...survey.questionsWithConnectionProblems, [question.id]: { question: question, ...data } }
      notifySurveyUpdate()
      console.debug('questionsWithConnectionProblems: ', question.id, survey.questionsWithConnectionProblems)
    }

    survey.removeQuestionWithConnectionProblems = (question) => {
      if (survey.questionsWithConnectionProblems[question.id]) {
        survey.questionsWithConnectionProblems = _.omit(survey.questionsWithConnectionProblems, question.id)
        notifySurveyUpdate()
        console.debug('questionsWithConnectionProblems: ', question.id, survey.questionsWithConnectionProblems)
      }
    }

    // add callbacks

    survey.onAfterRenderSurvey.add((survey) => {
      console.debug('SURVEY rendered')
      console.debug('SURVEY metadata', metaData)
      if (!isNewContent) {
        // set data as later as possible so that all question customizations are set up when receiving new data
        console.debug('SURVEY data ...', initialData)
        survey.data = initialData
      }
      notifySurveyUpdate()
    })

    // survey.onAfterRenderPage.add((survey, options) => {
    //   console.debug(`PAGE rendered ${options.page}`)
    // })

    // survey.onValueChanging.add((survey, options) => {
    //   // console.debug(`CHANGING ${options.question.name}`)
    //   // console.debug(JSON.stringify(options.oldValue, null, 2))
    //   // console.debug(JSON.stringify(options.question.value, null, 2))
    //   // console.debug(diff(options.oldValue,options.value))
    //   // console.debug("toBeDeletedFiles:",this.toBeDeletedFiles)
    //   // console.debug("brandNewFiles:",this.brandNewFiles)
    //   // console.debug(`New value for question ${options.question.name}: ${JSON.stringify(options.question.value, null, 2)}`)
    // })

    survey.onValueChanged.add((survey, options) => {
      console.debug(`QUESTION ${options.question.name} changed to: `, JSON.stringify(options.question.value, null, 2))
      keepSessionAlive()
      notifySurveyUpdate()
    })

    survey.onCurrentPageChanged.add((survey, options) => {
      console.debug(`PAGE changed ${options.newCurrentPage}`)
      keepSessionAlive()
      notifySurveyUpdate()
    })

    survey.onDynamicPanelItemValueChanged.add((survey, options) => {
      const panelDynamicQuestion = options.panel.parentQuestionValue
      if (panelDynamicQuestion.sortBy == options.name) {
        console.debug('resorting panels ...')
        panelDynamicQuestion.value = _.sortBy(panelDynamicQuestion.value, panelDynamicQuestion.sortBy)
        let newPanelIndex = panelDynamicQuestion.value.findIndex(p => p[options.name] == options.value)
        panelDynamicQuestion.panels[newPanelIndex].focusFirstQuestion()
      }
    })

    survey.onLoadChoicesFromServer.add((survey, options) => {
      let q = options.question
      if (q.choicesByUrl.processedUrl) {
        survey.removeWaitingForNetworkQuestion(q.id)
        if (q.choicesByUrl.error) {
          let textError
          try {
            let jsonError = JSON.parse(q.choicesByUrl.error.response)
            textError = jsonError.error || jsonError.msg
          }
          finally {
            textError ||= q.choicesByUrl.error.status
          }
          survey.addQuestionWithConnectionProblems(q, { url: q.choicesByUrl.processedUrl, error: textError })
        }
        else {
          survey.removeQuestionWithConnectionProblems(q)
        }
      }
    })

    survey.onDynamicPanelAdded.add((survey, options) => {
      let q = options.question
      const keyElement = q.templateElements.find(te => te.name == q.keyName && te.getType() == 'text' && te.inputType == 'number')
      // if the key question is a number set a default value for the key
      if (keyElement && keyElement.getType() == 'text' && keyElement.inputType == 'number') {
        let newValue = _.cloneDeep(q.value),
          addedElement = newValue.pop()
        _.defaults(addedElement, { [q.keyName]: _.max([q.panelCount, (_.max(_.map(newValue, q.keyName)) || 0) + 1]) })
        newValue.push(addedElement)
        q.value = newValue
      }
    })

    survey.onMatrixRowAdded.add((survey, options) => {
      let q = options.question
      const keyElement = q.getColumnByName(q.keyName)

      // the question value need to be modified

      // differently from paneldynamic matrixdynamic does not add the new row to the value immediately (only if there is a default row specified)
      let newValue = _.cloneDeep(q.value) || []
      if (newValue.length < q.rowCount) {
        newValue.push({})
      }

      // if the key question is a number set a default value for the key
      if (keyElement && keyElement.cellType == 'text' && keyElement.inputType == 'number') {
        let addedElement = newValue.pop()
        _.defaults(addedElement, { [q.keyName]: _.max([q.rowCount, (_.max(_.map(newValue, q.keyName)) || 0) + 1]) })
        newValue.push(addedElement)
      }

      q.value = newValue
    })

    // survey.onVisibleChanged.add((survey, options)=>{
    //   console.debug("EVENT onVisibleChanged FOR QUESTION", options.question.name, options.visible)
    // })

    survey.onGetQuestionTitleActions.add((sender, options) => {
      if (options.question.getType() === 'radiogroup') {
        options.titleActions = []
      }
    })

    survey.onUpdateQuestionCssClasses.add((survey, options) => {
      // console.debug("EVENT onUpdateQuestionCssClasses FOR QUESTION " + options.question.name, survey, options)
      // add generic question custom class styled in css
      options.cssClasses.mainRoot += ` cgp_qstn_${options.question.getType()}`
      if (!_NoConnectionProblems()) {
        options.cssClasses.error.root += ' d-none'
      }
      // only way to mark outermost panel is by js (by css is not possible)
      // if (options.question.getType() === "paneldynamic" && options.question.parent.getType() != "panel") {
      //   options.cssClasses.mainRoot += " cgp_paneldynamic_outermost_panel"
      // }
    })

    // survey.onUpdatePanelCssClasses.add((survey, options) => {
    //   // console.debug("EVENT onUpdatePanelCssClasses FOR PANEL " + options.panel.name)
    // })

    survey.onClearFiles.add((survey, options) => {
      if (options.value && options.value.length) {
        if (options.fileName) {
          setToBeDeletedFiles([...toBeDeletedFiles, _.find(options.value, ['name', options.fileName]).content])
        }
        else {
          setToBeDeletedFiles(toBeDeletedFiles.concat(_.map(options.value, 'content')))
        }
      }
      const brandNewFilesToBeDeleted = _.intersection(brandNewFiles, toBeDeletedFiles)
      options.callback('success') // TODO: keep this before async see https://github.com/surveyjs/survey-library/issues/1970
      cleanUpStorageService(brandNewFilesToBeDeleted)
        .then(() => {
          setBrandNewFiles(_.difference(brandNewFiles, brandNewFilesToBeDeleted))
          // reset toBeDeletedFiles when there are saved versions as some elements may be still in use
          setToBeDeletedFiles(savedVersions.length ? [] : _.difference(toBeDeletedFiles, brandNewFilesToBeDeleted))
          console.debug('toBeDeletedFiles:', toBeDeletedFiles)
          console.debug('brandNewFiles:', brandNewFiles)
        })
    })

    survey.onUploadFiles.add((survey, options) => {
      let promises = []
      options.files.forEach((file) => {
        let extDotIndex = file.name.lastIndexOf('.'),
          fileName = `${file.name.slice(0, extDotIndex)}_${Date.now()}`,
          ext = extDotIndex > 0 ? file.name.slice(extDotIndex) : '',
          formData = new FormData()

        fileName += ext
        _.forOwn(presignedPostData.fields, (v, k) => formData.append(k, v))
        formData.append('file', file, fileName)
        const pathToFile = `${actionPaths.downloadFile}?file_id=${fileName}`

        setBrandNewFiles([...brandNewFiles, pathToFile])

        promises.push(
          fetch(presignedPostData.url, {
            method: 'POST',
            body: formData,
          })
            .then((response) => {
              if (response.ok) {
                console.debug(`file ${pathToFile} uploaded`)
                return {
                  file: { name: fileName, type: file.type },
                  content: pathToFile,
                }
              }
              else {
                return {
                  error: {
                    file: file.name,
                    text: response.status == 413 ? 'File too large' : response.statusText,
                  },
                }
              }
            })
        )
      })
      Promise.all(promises).then((contents) => {
        contents = _.groupBy(contents, c => _.has(c, 'error'))
        contents[true] && contents[true].forEach(e => toastr.error(`The file ${e.error.file} were not uploaded onto the storage service: ${e.error.text}`))
        options.callback('success', contents[false] || [])
      })
    })

    survey.onTextMarkdown.add(markdownHandler)

    SjsUtils.addHelperFeature(survey)
  }

  const _calCchanges = () => {
    // console.debug("Calculating changes ...")
    return _isCurrentVersion ? diff(content.data, window.survey.data) : undefined
  }

  const cleanUpStorageService = (files) => {
    let promises = []

    files.forEach(path =>
      promises.push(
        fetch(path, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': window.csrfToken,
          },
        })
          .then((response) => {
            if (response.ok) {
              console.debug(`file ${path} deleted`)
            }
            else {
              return {
                path: path,
                error: response.statusText,
              }
            }
          })
      )
    )
    return Promise.all(promises).then((errors) => {
      _.compact(errors).forEach(e => toastr.error(`The file ${e.path} were not removed from the storage service: ${e.error}`))
    })
  }

  const showErrors = () => {
    setShowPageErrorsHeader(true)
  }

  const dismissPageErrorsHeader = () => {
    setShowPageErrorsHeader(false)
  }

  const pageChangedHandler = (newPageNum) => {
    setIsWorking(true)
    setTimeout(() => {
      setCurrentPageNo(newPageNum)
    })
  }

  const versionName = (v) => {
    return moment(v.timestamp).format('l LT') + ' ' + v.name
  }

  const handleAction = (action, arg) => {
    switch (action) {
      case 'save': {
        console.debug('about to send -> ' + JSON.stringify(window.survey.data))
        xFetch(actionPaths.save, {
          method: 'PATCH',
          body: JSON.stringify({ collector_content: window.survey.data }),
          headers: { 'X-will-redirect': true },
        }).then(() => {
          cleanUpStorageService(toBeDeletedFiles).then(() => window.location.reload())
        })
        break
      }
      case 'reset':
        rebuildSurvey()
        cleanUpStorageService(brandNewFiles).then(() => {
          setBrandNewFiles([])
          setToBeDeletedFiles([])
          // console.debug('toBeDeletedFiles:', toBeDeletedFiles)
          // console.debug('brandNewFiles:', brandNewFiles)
          toastr.info('content reset to last version')
        })
        break
      case 'changeVersion':
        if (arg == 'current') {
          setInitialData(content.data)
          setSelectedVersionId(content.id)
          toastr.info('switched to current version')
        }
        else {
          xFetch(actionPaths.readVersion, {
            query: { versionId: arg },
          }).then((body) => {
            setInitialData(body.data)
            setSelectedVersionId(body.id)
            toastr.info('switched to version <br>' + versionName(body.version))
          })
        }
        break
      case 'deleteVersion':
        xFetch(actionPaths.manageVersion, {
          method: 'DELETE',
          body: JSON.stringify({ version: { id: arg } }),
        })
          .then((body) => {
            setInitialData(content.data)
            setSavedVersions(_.reject(savedVersions, e => e.id == body.id))
            setSelectedVersionId(content.id)
            toastr.info('version ' + versionName(body.version) + ' deleted correctly')
          })
        break
      case 'createVersion':
        arg.timestamp = moment(arg.timestamp).toISOString()
        xFetch(actionPaths.manageVersion, {
          method: 'POST',
          body: JSON.stringify({ version: arg }),
        })
          .then((body) => {
            setInitialData(body.data)
            setSelectedVersionId(body.id)
            setSavedVersions(body.saved_versions)
            toastr.info('The version <br>' + versionName(body.version) + ' was saved')
          })
        break
      case 'updateVersion':
        arg.timestamp = moment(arg.timestamp).toISOString()
        xFetch(actionPaths.manageVersion, {
          method: 'PATCH',
          body: JSON.stringify({ version: arg }),
        })
          .then((body) => {
            setSavedVersions(body.saved_versions)
            toastr.info('The version <br>' + this.versionName(body.version) + ' was updated')
          })
        break
    }
  }

  const updateAddressBar = () => {
    let newUrl = new URL(window.location)
    if (currentPageNo) {
      newUrl.searchParams.set('pageNumber', currentPageNo + 1)
    }
    else {
      newUrl.searchParams.delete('pageNumber')
    }
    history.pushState({}, null, newUrl)
  }

  let pageLabels = window.survey ? window.survey.visiblePages.map(page => page.title || page.name) : []
  const goToCollectorTooltip
    = (
      <Tooltip id="goToCollectorTooltip">
        go to the
        {' '}
        <b>
          {collector.name}
        </b>
        {' '}
        form model page
      </Tooltip>
    )

  const toolbar = isWorking
    ? null
    : (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <ButtonToolbar>
            <ButtonGroup className="mr-2">
              <OverlayTrigger
                overlay={(
                  <Tooltip>
                    Click to show current form data
                  </Tooltip>
                )}
                placement="top"
              >
                <Button onClick={() => setShowChanges(true)} variant={isSavedAndChanged ? 'warning' : 'secondary'}><i className="fas fa-eye" /></Button>
              </OverlayTrigger>
              {
                isSavedAndChanged
                  ? (
                    <OverlayTrigger
                      overlay={(
                        <Tooltip>
                          Click to undo changes
                        </Tooltip>
                      )}
                      placement="top"
                    >
                      <Button disabled={!_isEditable} onClick={() => handleAction('reset')} variant="warning"><i className="fas fa-undo" /></Button>
                    </OverlayTrigger>
                    )
                  : null
              }
            </ButtonGroup>
          </ButtonToolbar>
          {
            isSavedAndChanged
              ? (
                <span className="align-self-center text-warning">
                  <i className="fa fa-exclamation-triangle" />
                  {' '}
                  Changes detected
                </span>
                )
              : null
          }
        </div>
        <ButtonToolbar>
          <ButtonGroup>
            {
              pagesWithErrors.length
                ? (
                  <OverlayTrigger
                    overlay={(
                      <Tooltip>
                        <strong>The form is incomplete or contains errors</strong>
                        . Click to highlight all errors (They must be resolved in order to save)
                      </Tooltip>
                    )}
                    placement="top"
                  >
                    <Button onClick={showErrors} variant="danger"><i className="fas fa-exclamation-circle" /></Button>
                  </OverlayTrigger>
                  )
                : null
            }
            <Button bsPrefix={`btn btn-sjs-save ${isChanged || isNewContent ? 'btn-primary' : 'btn-success saved'}`} disabled={!isChanged || !_isEditable || pagesWithErrors.length} onClick={() => handleAction('save')} />
          </ButtonGroup>
        </ButtonToolbar>
      </div>
      )

  return (
    <div className="card collector-content">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <div className="card-title">
              <h3 className="mb-0">
                {collector.name}
                {
                  actionPaths.collector
                    ? (
                      <OverlayTrigger delayShow={500} overlay={goToCollectorTooltip} placement="top">
                        <small>
                          <a className="ml-2" onClick={() => window.open(actionPaths.collector, '_blank')}>
                            <i className="fas fa-file-invoice" />
                          </a>
                        </small>
                      </OverlayTrigger>
                      )
                    : collector.name
                }
              </h3>
              {
                collector.tag_list.length
                  ? collector.tag_list.map(tag => (
                    <div className="badge badge-light" key={tag}>
                      {tag}
                    </div>
                  ))
                  : null
              }
            </div>
          </div>
          <div className="col-md-4">
            {
                !isNewContent && !isChanged && !!actionPaths.readVersion
                  ? (
                    <Versioner
                      can_manage_versions={!!actionPaths.manageVersion}
                      handleAction={handleAction}
                      savedVersions={savedVersions}
                      selectedVersion={_selectedVersion}
                      title={collector.name}
                    />
                    )
                  : null
              }
          </div>
        </div>
        {
          pageLabels.length > 1
            ? (
              <div className="row mt-2">
                <div className="col-12">
                  <Navigator
                    currentPageNo={currentPageNo}
                    handleChangePage={pageChangedHandler}
                    pages={pageLabels}
                  />
                </div>
              </div>
              )
            : null
        }
        <div className="row mt-2">
          <div className="col-12">
            {toolbar}
          </div>
        </div>
      </div>
      <WithSpinner isLoading={isWorking}>
        <div className="card-body">
          {
              window.survey
                ? (
                  <>
                    <PageErrorsHeader handleDismiss={dismissPageErrorsHeader} pagesWithErrors={pagesWithErrors} show={showPageErrorsHeader} />
                    <ConnectionErrorsHeader detailsEnabled={true} questions={window.survey.questionsWithConnectionProblems} />
                    <Survey
                      currentPageNo={currentPageNo}
                      model={window.survey}
                    />
                  </>
                  )
                : null
            }
        </div>
      </WithSpinner>
      <div className="card-footer mt-2">
        <div className="row">
          <div className="col-12">
            {toolbar}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <Userstamp {..._.pick(content, ['created_at', 'creator', 'updated_at', 'updater'])} />
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="modal-90w"
        onHide={() => setShowChanges(false)}
        show={showChanges}
      >
        <Modal.Header>
          <Modal.Title>Current form data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RawJsonDataViewer changes={changes} initialData={initialData} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowChanges(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

CollectorContent.propTypes = {
  actionPaths: PropTypes.object,
  content: PropTypes.object,
  metaData: PropTypes.object,
  pageNumber: PropTypes.string,
  collector: PropTypes.object.isRequired,
}

export default CollectorContent
