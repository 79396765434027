import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { Button, Modal, ButtonGroup } from "react-bootstrap"

function DatabaseWidgetModal(props) {

  function detroyDb() {
    props.handleDestroyDb()
    props.handleClose(false)
  }

  function handleCancel() {
    props.handleClose(false)
  }


  return <Modal
    show={props.show}
    onHide={handleCancel}
  >
    <Modal.Header>
      <Modal.Title>
        <span><i className="fa fa-database"/> Exported database properties</span>
        <br/>
        {
          props.exportDbData.success
            ? <small>last sync: {moment(props.exportDbData.updated_at).format("DD/MM/YYYY HH:mm:ss")}</small>
            : null
        }
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      {
        props.exportDbData.is_ready
        ? props.exportDbData.success
          ? <pre>
              <code>
                {JSON.stringify(props.exportDbData.database_params, null, 2)}
              </code>
            </pre>
          : <span>Database was not generated. <br /><strong>{props.exportDbData.last_outcome_message}</strong></span>
        : <p className="text-center">
            <strong>Database is under construction.</strong>
            <br />
            {
              props.isLoading
              ? "checking now ..."
              : <span className="ml-1">
                Please try later or <a href="#" onClick={props.handleRefresh}>check now</a>
              </span>
            }
          </p>
      }

    </Modal.Body>
    <Modal.Footer className="d-flex justify-content-between">
      {
        props.canManage && props.exportDbData.is_ready
        ? (
          <div>
            <ButtonGroup>
              <Button
                variant="outline-warning"
                onClick={props.handleSyncDbNow}
                title="sync database"
                disabled={props.isLoading || !props.exportDbData.success}
              >
                <span>
                  <i className="fa fa-sync"/> sync database
                </span>
              </Button>
              <Button
                variant="outline-danger"
                onClick={detroyDb}
                title={"destroy database"}
                disabled={props.isLoading}
              >
                <span>
                  <i className="fa fa-trash"/> destroy database
                </span>
              </Button>
            </ButtonGroup>
            {
              props.isLoading
              ? <span className="ml-2"><i className="fas fa-spinner fa-spin"></i></span>
              : null
            }
          </div>
          )
        :
        null
      }
      <Button
        onClick={handleCancel}
        variant="secondary"
      >
        Close
      </Button>
    </Modal.Footer>
  </Modal>
}

DatabaseWidgetModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSyncDbNow: PropTypes.func.isRequired,
  handleDestroyDb: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  exportDbData: PropTypes.object.isRequired,
  canManage: PropTypes.bool,
  isLoading: PropTypes.bool
}

DatabaseWidgetModal.defaultProps = {
  show: false,
  canManage: false
}

export default DatabaseWidgetModal
