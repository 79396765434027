import React from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"

export default class Relative extends React.Component {
  constructor (props) {
    super(props)
  }

  static defaultProps = {
    onlyRead: true
  }

  static propTypes = {
    relative: PropTypes.object,
    role: PropTypes.string.isRequired,
    onUnlink: PropTypes.func,
    onlyRead: PropTypes.bool
  }

  _startUnlinkingRelative = (e) => {
    e.preventDefault()
    this.props.onUnlink(this.props.relative.id,this.props.role)
  }

  render() {

    const p = this.props.relative
    let name, dates

    const genderIcon = (person) => {
      switch(person.sex) {
      case "male":
        return <i className="fa fa-mars" aria-hidden="true"></i>
      case "female":
        return <i className="fa fa-venus" aria-hidden="true"></i>
      default:
        return null
      }
    }

    const cohorts = p.cohorts && p.cohorts.map(c => <div key={c.id} className="badge cohort-badge">{c.name}</div>)

    if (!_.isNil(p)) {
      dates = _.map(_.compact([p.dob,p.dod]), d => moment(d).format()).join(" → ")
      name = <h6>{p.fullname || p.id}<small><br />{dates}</small></h6>
    }

    return (
      <a href={p && p.path_to_relatives_home}>
        <div className="small-box bg-info mb-0">
          <div className="inner">
            <h5>{_.upperCase(this.props.role)}</h5>
            {p && name}
            { cohorts }
          </div>
          <div className="icon">
            {genderIcon(p)}
          </div>
          {
            !this.props.onlyRead &&
              <div className="small-box-footer text-right pr-1">
                <Button variant="warning" size="sm" onClick={this._startUnlinkingRelative}>unlink</Button>
              </div>
          }
        </div>
      </a>
    )

  }
}

