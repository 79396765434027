import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table'
import BTable from 'react-bootstrap/Table'
import styled from 'styled-components'
import LoadingOverlay from 'react-loading-overlay'
import { HelpPopper } from '../utils'

LoadingOverlay.propTypes = undefined // workaround to remove warning https://github.com/derrickpelletier/react-loading-overlay/pull/57#issuecomment-1054194254

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
  .table-responsive {
    max-height: 30vh;
    resize: vertical;
    table {
      white-space: nowrap;
      height: 100%;
      thead tr th {
        position: sticky;
        top: 0;
      }
    }
  }
  .osr-results {
    h6 {
      color: #E87600;
    }
    table {
      th {
        background-color: #FFB463;
      }
      th,td {
        border-color: #FFB463;
      }
      background-color: #FFD6A9;
    }
  }
`

export default function SearchResults({
  searchResults,
  loading,
  actionCell,
  showCgpResults = true,
  showOsrResults = true,
  willAdd,
}) {
  // console.debug('render SearchResults')

  const [cgpTableColumns, osrTableColumns] = useMemo(() => {
    const columnHelper = createColumnHelper()

    let cgpTableColumns, osrTableColumns, defaultColumns = [
      columnHelper.accessor('name', { header: 'Name' }),
      columnHelper.accessor('surname', { header: 'Surname' }),
      columnHelper.accessor('sex', { header: 'Sex' }),
      columnHelper.accessor('dob', { header: 'Date of Birth', cell: info => info.getValue() && moment(info.getValue()).format() }),
      columnHelper.accessor('taxcode', { header: 'Fiscal Code' }),
    ]

    cgpTableColumns = [
      columnHelper.accessor('id', { header: 'pubID' }),
      columnHelper.accessor('osr_id', { header: 'osrID' }),
      ...defaultColumns,
      columnHelper.accessor('cohort_names', { header: 'Cohorts' }),
    ]

    osrTableColumns = [
      columnHelper.accessor('osr_id', { header: 'osrID' }),
      ...defaultColumns,
    ]

    return [cgpTableColumns, osrTableColumns].map(cols => [
      ...cols,
      actionCell
        ? columnHelper.display({
          id: 'actions',
          cell: props => actionCell(props.row.original),
          isAction: true,
        })
        : [],
    ].flat()
    )
  }, [searchResults?.cgp_people])

  const [cgpData, osrData] = useMemo(() => [searchResults?.cgp_people || [], searchResults?.osr_patients || []], [searchResults])

  const alreadyLinkedOsrIds = _.compact(_.map(cgpData, 'osr_id'))

  const cgpTable = useReactTable({
    data: cgpData,
    columns: cgpTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const osrTable = useReactTable({
    data: osrData,
    columns: osrTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <Styles>
      <div className="card">
        <div className="card-body">
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: base => ({
                ...base,
                background: 'rgba(0, 0, 0, 0.3)',
              }),
            }}
            text="Loading ..."
          >
            {
              showCgpResults
                ? (
                  <div>
                    <h6>
                      <strong>
                        {`${cgpTable.getRowModel().rows.length} people found in the CGP database` }
                      </strong>
                      {
                        willAdd
                          ? (
                            <HelpPopper>
                              <span>By choosing one of them you will reuse an existing CGP person so no new people will be created. It will be simply added to the specified cohorts</span>
                            </HelpPopper>
                            )
                          : null
                      }
                    </h6>
                    <BTable hover responsive size="sm">
                      <thead className="thead-light">
                        {cgpTable.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                              <th className={header.id == 'actions' ? 'actions-cell' : null} colSpan={header.colSpan} key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : (
                                    <div onClick={header.column.getToggleSortingHandler()}>
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {{
                                        asc: ' ▲',
                                        desc: ' ▼',
                                      }[header.column.getIsSorted()] ?? null}
                                    </div>
                                    )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {cgpTable.getRowModel().rows.map(row => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                              <td className={cell.column.id == 'actions' ? 'actions-cell' : null} key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </BTable>
                  </div>
                  )
                : null
            }
            {
              showOsrResults
                ? (
                  <div className="osr-results mt-4">
                    <h6>
                      <strong>
                        {`${osrTable.getRowModel().rows.length} patients found in the OSR database` }
                      </strong>
                      {
                        willAdd
                          ? (
                            <HelpPopper>
                              <p>
                                By choosing one of them a new person (with a new pubID) will be added to CGP database and it will be linked to the selected OSR patient. It will be also added to the specified cohorts
                              </p>
                              <p>
                                OSR patient already linked to CGP people cannot be added.
                              </p>
                            </HelpPopper>
                            )
                          : null

                      }
                    </h6>
                    <BTable hover responsive size="sm">
                      <thead className="thead-light">
                        {osrTable.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                              <th className={header.id == 'actions' ? 'actions-cell' : null} colSpan={header.colSpan} key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : (
                                    <div onClick={header.column.getToggleSortingHandler()}>
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {{
                                        asc: ' ▲',
                                        desc: ' ▼',
                                      }[header.column.getIsSorted()] ?? null}
                                    </div>
                                    )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {osrTable.getRowModel().rows.map(row => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                              <td className={cell.column.id == 'actions' ? 'actions-cell' : null} key={cell.id}>
                                {
                                  cell.column.id == 'actions' && _.includes(alreadyLinkedOsrIds, row.original.osr_id)
                                    ? null
                                    : flexRender(cell.column.columnDef.cell, cell.getContext())
                                }
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </BTable>
                  </div>
                  )
                : null
            }
          </LoadingOverlay>
        </div>
      </div>
    </Styles>
  )
}

SearchResults.propTypes = {
  searchResults: PropTypes.object,
  loading: PropTypes.bool,
  actionCell: PropTypes.func,
  showCgpResults: PropTypes.bool,
  showOsrResults: PropTypes.bool,
  willAdd: PropTypes.bool.isRequired,
}
