import React from "react"
import PropTypes from "prop-types"
import Sample from "./sample"
import NewSample from "./new-sample"
import { Button } from "react-bootstrap"
import { xFetch } from "../utils"
import LoadingOverlay from "react-loading-overlay"
LoadingOverlay.propTypes = undefined // workaround to remove warning https://github.com/derrickpelletier/react-loading-overlay/pull/57#issuecomment-1054194254

export default class App extends React.Component {

  static propTypes = {
    personId: PropTypes.string.isRequired,
    forms: PropTypes.array.isRequired,
    projectId: PropTypes.number,
    canCreate: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      samples: [],
      loading: false,
      isCreating: false
    }
  }

  static defaultProps = {
    canCreate: false
  }

  componentDidMount() {
    this.setState({
      loading: true,
    }, () => xFetch(Routes.visible_to_forms_samples_path(this.props.personId, this.props.forms.map(c => c.id)),{
      query: {include_invisible: true}
    })
      .then( data =>
        this.setState({
          samples: data,
          loading: false
        })
      )
    )
  }

  handleCreateSample = (sampleParams) => {
    sampleParams.projectId = this.props.projectId
    this.setState({
      loading: true,
    }, () => xFetch(Routes.samples_path(this.props.personId), {
      method: "POST",
      body: JSON.stringify({sample: sampleParams}),
    })
      .then( newSample => {
        this.setState(prevState => ({
          samples: [...prevState.samples, newSample],
          isCreating: false
        }), () => toastr.success("Sample correctly created"))
      })
      .catch(error => {
        toastr.error(_.join(error.msgs, "<br>"), "Sample was not created")
      })
      .finally(() => this.setState({
        loading: false
      }))

    )
  }

  handleUpdateSampleForms = (sampleParams,action,changedFormId) =>
    this.setState({
      loading: true,
    }, () => xFetch(Routes[`${action}_form_sample_path`](this.props.personId,sampleParams.code,changedFormId), {
      method: "PATCH",
    })
      .then(updatedSample =>
        this.setState(prevState => {
          let samples = [...prevState.samples]
          let foundIndex = samples.findIndex(x => x.code == updatedSample.code)
          samples[foundIndex] = updatedSample
          return {
            samples: samples
          }
        }, () => toastr.success("Forms that can use this sample correctly updated"))
      )
      .catch(error => {
        toastr.error(_.join(error.msgs, "<br>"), "Forms that can use this sample were not updated")
      })
      .finally(() => this.setState({
        loading: false
      }))
    )

  handleDeleteSample = (sampleCode) => {
    this.setState({
      loading: true,
    }, () => xFetch(Routes.sample_path(this.props.personId,sampleCode), {
      method: "DELETE",
    })
      .then(deletedSample => {
        this.setState(prevState => {
          let samples = [...prevState.samples]
          _.remove(samples, s => s.code == deletedSample.code)
          return {
            samples: samples
          }
        }, () => toastr.success("Sample correctly deleted"))
      })
      .catch(error => {
        toastr.error(_.join(error.msgs, "<br>"), "Sample was not deleted")
      })
      .finally(() => this.setState({
        loading: false
      }))
    )
  }



  toggleNewSampleForm = () => {
    this.setState(prevState => ({
      isCreating: !prevState.isCreating
    }))
  }

  render() {

    return (
      <LoadingOverlay
        active={this.state.loading}
        text='Loading ...'
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0, 0, 0, 0.3)",
            minHeight: "300px"
          })
        }}
      >
        <div className="row small-boxes-list">
          {
            (_.isEmpty(this.state.samples) && !this.state.isCreating) ?
              !this.state.loading && <div className="col-12">no samples</div>
              :
              this.state.samples.map((s) =>
                <div key={s.code} className="col-md-4 col-6 mb-2">
                  <Sample
                    forms={this.props.forms}
                    loading={this.state.loading}
                    sample={s}
                    onDelete={s.can_be_deleted && this.handleDeleteSample}
                    onHandleRemoveForm={(changedFormId) => this.handleUpdateSampleForms(s,"remove",changedFormId)}
                    onHandleAddForm={(changedFormId) => this.handleUpdateSampleForms(s,"add",changedFormId)}
                  />
                </div>
              )
          }
          {
            this.state.isCreating &&
              <div key="newSample" className="col-md-4 col-6 mb-2">
                <NewSample
                  loading={this.state.loading}
                  onSave={this.handleCreateSample}
                  onCancel={this.toggleNewSampleForm}
                />
              </div>
          }
        </div>
        {
          this.props.canCreate && !this.state.isCreating &&
          <div className="row">
            <div className="col-12">
              <Button disabled={this.state.loading} className="pull-right" variant='primary' onClick={this.toggleNewSampleForm}>
                Add Sample <i className="fa fa-plus" />
              </Button>
            </div>
          </div>
        }
      </LoadingOverlay>
    )
  }
}
