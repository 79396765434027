import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import * as htmlFormatter from 'jsondiffpatch/formatters/html'

function RawJsonDataViewer({
  changes,
  initialData,
}) {
  const [showOnlyChanges, setShowOnlyChanges] = useState(changes ? true : false)

  if (showOnlyChanges) {
    htmlFormatter.hideUnchanged()
  }
  else {
    htmlFormatter.showUnchanged()
  }

  const html = htmlFormatter.format(changes || {}, initialData)

  return (
    <>
      <div>
        <Form>
          <Form.Group>
            <Form.Check id="showOnlyChanges">
              <Form.Check.Input
                checked={showOnlyChanges}
                disabled={!changes}
                onChange={e => setShowOnlyChanges(e.target.checked)}
                type="checkbox"
              />
              <Form.Check.Label>
                Show only changed values
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Form>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </>
  )
}

RawJsonDataViewer.propTypes = {
  changes: PropTypes.object,
  initialData: PropTypes.object,
}

export default RawJsonDataViewer
