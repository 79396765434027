import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Card, Form } from "react-bootstrap"
import styled from "styled-components"
import { bootstrapSelectStyle } from "../../utils"
import Select from "react-select"

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
`

const nameinvalidFeedback = <Form.Control.Feedback type="invalid">
  <ul className="pl-3">
    <li>Required</li>
    <li>must not be present in parent sheet</li>
    <li>can contain only letters, digits and underscores</li>
    <li>must not start with an underscore</li>
  </ul>
</Form.Control.Feedback>


function AggFields(props) {

  const [data, setData] = useState({
    field: props.selectedSheetIsMadeOfScalars ? _.first(props.fieldOpts).value : undefined,
    name: props.selectedSheetIsMadeOfScalars ? _.last(props.sheetId.split(".")) : undefined,
    formula: undefined
  })
  const [invalidFields, setInvalidFields] = useState({})
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    const invalidFields = {
      field: !data.field,
      name: !data.name || props.arePresentInParent([data.name]) || !data.name.match(props.fieldNameRegexp),
      formula: !data.formula
    }
    setInvalidFields(invalidFields)
    setValidated(true)
    props.handleChange("agg",data,_.some(invalidFields))
  }, [data])

  const aggFormulasByType = {
    string: ["count","concat"],
    number: ["count","concat","min","max","sum","avg"],
    boolean: []
  }
  const formulaOpts = _.map(data.field && props.fieldsData[data.field] ? aggFormulasByType[props.fieldsData[data.field].contentType] : [], k => ({value: k, label: k}) )

  return <>
    <Form.Group controlId="form.field">
      <Form.Label>Field</Form.Label>
      <Select
        styles={bootstrapSelectStyle}
        isInvalid={invalidFields["field"]}
        validated={validated}
        className={invalidFields["field"] && "is-invalid" }
        components={{ IndicatorSeparator:() => null }}
        options={props.fieldOpts}
        onChange={opt => setData({...data, field: opt.value, name: [_.last(props.sheetId.split(".")), props.selectedSheetIsMadeOfScalars ? null : opt.value].join("_"), formula: undefined})}
        placeholder="Please select ...."
        value={_.find(props.fieldOpts, opt => data.field == opt.value) || null }
      />
      <div className="invalid-feedback">Required</div>
    </Form.Group>
    <Form.Group controlId="form.formula">
      <Form.Label>Formula</Form.Label>
      <Select
        styles={bootstrapSelectStyle}
        isInvalid={invalidFields["formula"]}
        validated={validated}
        className={invalidFields["formula"] && "is-invalid" }
        components={{ IndicatorSeparator:() => null }}
        options={formulaOpts}
        onChange={opt => setData({...data, formula: opt.value })}
        placeholder="Please select ...."
        value={_.find(formulaOpts, opt => opt.value == data.formula) || null }
      />
      <div className="invalid-feedback">Required</div>
    </Form.Group>
    <Form.Group controlId="form.name">
      <Form.Label>New field name</Form.Label>
      <Form.Control isInvalid={invalidFields["name"]} type="text" placeholder="new field name" onChange={e => setData({...data, name: e.target.value})} value={data.name || ""}/>
      {nameinvalidFeedback}
    </Form.Group>
  </>
}

AggFields.propTypes = {
  sheetId: PropTypes.string,
  fieldsData: PropTypes.object,
  fieldOpts: PropTypes.array.isRequired,
  arePresentInParent: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedSheetIsMadeOfScalars: PropTypes.bool,
  fieldNameRegexp: PropTypes.instanceOf(RegExp).isRequired
}


function ExpFields(props) {

  const [data, setData] = useState({
    fields: props.selectedSheetIsMadeOfScalars ? [_.first(props.fieldOpts).value] : [],
    prefix: _.last(props.sheetId.split(".")).concat("_")
  })
  const [invalidFields, setInvalidFields] = useState({})
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    const invalids = {
      fields: !data.fields.length,
      prefix: !data.prefix || !data.prefix.match(props.fieldNameRegexp),
    }
    setInvalidFields(invalids)
    setValidated(true)
    props.handleChange("exp",data,_.some(invalids))
  }, [data])

  return <>
    <Form.Group controlId="form.fields">
      <Form.Label>Fields</Form.Label>
      <Select
        styles={bootstrapSelectStyle}
        isInvalid={invalidFields["fields"]}
        validated={validated}
        className={invalidFields["fields"] && "is-invalid" }
        components={{ IndicatorSeparator:() => null }}
        options={props.fieldOpts}
        onChange={opts => setData({...data, fields: _.map(opts,"value")})}
        placeholder="Please select ...."
        value={_.filter(props.fieldOpts, opt => data.fields.includes(opt.value)) }
        isMulti={true}
      />
      <div className="invalid-feedback">Required</div>
    </Form.Group>
    <Form.Group controlId="form.prefix">
      <Form.Label>Prefix</Form.Label>
      <Form.Control isInvalid={invalidFields["prefix"]} type="text" placeholder="prefix for new columns" onChange={e => setData({...data, prefix: e.target.value})} value={data?.prefix || ""}/>
      {nameinvalidFeedback}
    </Form.Group>
  </>
}

ExpFields.propTypes = {
  sheetId: PropTypes.string,
  fieldOpts: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  fieldNameRegexp: PropTypes.instanceOf(RegExp).isRequired
}

function LiftUpStepForm(props) {

  const [data, setData] = useState({})
  const [invalidFields, setInvalidFields] = useState({})
  const [someInvalidNestedFields, setSomeInvalidNestedFields] = useState(true)
  const [validated, setValidated] = useState(false)

  const parentFields = data.sheetId && _.map(props.workbook[data.sheetId].parent.fields,"name")

  const arePresentInParent = fields => !!_.intersection(parentFields,fields).length

  const handleChange = (type,aggData,someInvalidNestedFields) => {
    setData({...data, type: type, ...aggData})
    setSomeInvalidNestedFields(someInvalidNestedFields)
  }

  useEffect(() => {
    if (!_.isEqual(data,{})) {
      setInvalidFields({
        sheetId: !data.sheetId,
        type: !data.type,
      })
      setValidated(true)
    }
  }, [data])

  const fieldsData = _.mapValues(props.workbook, (sheet, sheetId) => {
    return _.keyBy(_.filter(sheet.fields, f => f.type == "orig" || f.type == "liftedUp"), "name") // only original data fields and liftedUp fields can be lifted up
  }, {})
  delete fieldsData.main

  const sheetIdOpts = _.map(_.keys(fieldsData), k => ({value: k, label: k}) )
  const fieldOpts =_.map(fieldsData[data.sheetId], f => ({value: f.name, label: f.name}) )
  const typeOpts = [{value: "agg", label: "aggregated (AGG)"},{value: "exp", label: "expanded (EXP)"}]

  const fieldsProps = {
    key: data.sheetId, // so that sub component is completely remount (reset its state) when sheetId change
    sheetId: data.sheetId,
    arePresentInParent: arePresentInParent,
    handleChange: handleChange,
    selectedSheetIsMadeOfScalars: props.workbook[data.sheetId]?.isMadeOfScalars,
    fieldOpts: fieldOpts,
    fieldsData: fieldsData[data.sheetId],
    fieldNameRegexp: props.fieldNameRegexp,
  }
  const typeSpecificFields = {
    agg: <AggFields {...fieldsProps}/>,
    exp: <ExpFields {...fieldsProps}/>
  }

  return <Styles>
    <Card bsPrefix="card card-secondary">
      <Card.Header>
        <Card.Title>
          New step
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form noValidate >

          <Form.Group controlId="form.type">
            <Form.Label>Type</Form.Label>
            <Select
              styles={bootstrapSelectStyle}
              isInvalid={invalidFields["type"]}
              validated={validated}
              className={invalidFields["type"] && "is-invalid" }
              components={{ IndicatorSeparator:() => null }}
              options={typeOpts}
              onChange={opt => setData({sheetId: data.sheetId, type: opt.value })}
              placeholder="Please select ...."
              value={_.find(typeOpts, opt => opt.value == data.type) || null }
            />
            <div className="invalid-feedback">Required</div>
          </Form.Group>

          <Form.Group controlId="form.sheetId">
            <Form.Label>Sheet</Form.Label>
            <Select
              styles={bootstrapSelectStyle}
              isInvalid={invalidFields["sheetId"]}
              validated={validated}
              className={invalidFields["sheetId"] && "is-invalid" }
              components={{ IndicatorSeparator:() => null }}
              options={sheetIdOpts}
              onChange={opt => setData({type: data.type, sheetId: opt.value})}
              placeholder="Please select ...."
              value={_.find(sheetIdOpts, opt => opt.value == data.sheetId) || null }
            />
            <div className="invalid-feedback">Required</div>
          </Form.Group>

          {
            data.type && data.sheetId ?
              <>
                {typeSpecificFields[data.type]}
              </>
              :
              null
          }

          <Button disabled={_.some(invalidFields) || someInvalidNestedFields} variant="primary" onClick={() => props.handleSubmit(data)}>
            add step
          </Button>
          <Button variant="link" onClick={() => props.handleCancel()}>
            cancel
          </Button>
        </Form>
      </Card.Body>
    </Card>
  </Styles>
}

LiftUpStepForm.propTypes = {
  workbook: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  fieldNameRegexp: PropTypes.instanceOf(RegExp).isRequired
}

export default LiftUpStepForm
