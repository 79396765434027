import React, {useState} from "react"
import PropTypes from "prop-types"
import RemoveFromCohortDialog from "./remove_from_cohort_dialog"
import { Form, Button } from "react-bootstrap"
import { bootstrapSelectStyle, xFetch } from "./utils"
import Select from "react-select"

function PersonCohortsLister(props) {

  const [cohortToBeRemovedFrom, setCohortToBeRemovedFrom] = useState()
  const [cohortsToBeAddedTo, setCohortsToBeAddedTo] = useState()

  const addPersonToCohort = cohortId =>
    xFetch(Routes.add_member_cohort_path(cohortId), {
      method: "PATCH",
      headers: { "X-will-redirect": true },
      body: JSON.stringify({
        pid: props.person.id
      })
    })

  const addPersonToSelectedCohortIds = () =>
    // add to all selected cohorts sequentially https://jrsinclair.com/articles/2019/how-to-run-async-js-in-parallel-or-sequential/
    cohortsToBeAddedTo.reduce(
      (p, c) => p.then(() => addPersonToCohort(c.value)),
      Promise.resolve(null)
    )
      .then( () => {
        window.location.reload()
      })


  const rows = _.values(_.pick(props.cohorts,props.person.cohortIds)).map(c => <tr key={c.id}>
    <td>
      {c.id}
    </td>
    <td>
      <a href={c.cohortUrl}>{c.name}</a>
    </td>
    <td>
      {_.join(_.map(c.projects,"name"),", ")}
    </td>
    <td>
      <Button variant='danger' size="xs" disabled={!c.canAddRemove} onClick={e => setCohortToBeRemovedFrom(c)}>remove</Button>
    </td>
  </tr>)

  return <>
    <table className="table table-borderless table-hover">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Projects</th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
    {
      cohortToBeRemovedFrom && <RemoveFromCohortDialog
        pid={props.person.id}
        cid={cohortToBeRemovedFrom.id}
        handleClose={setCohortToBeRemovedFrom}
      />
    }

    <Form inline>
      <Select
        styles={bootstrapSelectStyle}
        className="mr-2 col-md-8"
        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        options={_.filter(_.omit(props.cohorts,props.person.cohortIds),"canAddRemove").map(c => ({
          value: c.id,
          label: c.name
        }))}
        onChange={setCohortsToBeAddedTo}
        placeholder="Please select to add to another cohort ...."
        isMulti={true}
      />
      <Button disabled={_.isEmpty(cohortsToBeAddedTo)} onClick={addPersonToSelectedCohortIds}>
        Add
      </Button>
    </Form>
  </>
}

PersonCohortsLister.propTypes = {
  cohorts: PropTypes.object,
  person: PropTypes.object.isRequired
}

PersonCohortsLister.defaultProps = {
  cohorts: {}
}

export default PersonCohortsLister


