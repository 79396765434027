var remoteObjects = {}
function getRemoteObjectAjax(context, url, isReadyCallback) {
  if (!url) {
    isReadyCallback({
      isFound: false
    })
    return null
  }
  let result = remoteObjects[url.href]
  if (result) {
    isReadyCallback(result)
  } else {
    $.ajax({
      url: url,
      type: "GET",
      beforeSend: (jqXHR,settings) => {
        context.survey.addWaitingForNetworkQuestion(context.question.id)
      },
      success: function(data) {
        context.survey.removeWaitingForNetworkQuestion(context.question.id)
        context.survey.removeQuestionWithConnectionProblems(context.question)

        let res
        if (!data) {
          res = {
            isFound: false
          }
        } else {
          res = {
            status: "completed",
            isFound: true,
            data: data
          }
        }
        remoteObjects[url.href] = res
        isReadyCallback(res)
      },
      error: function(xhr, ajaxOptions, thrownError) {
        context.survey.removeWaitingForNetworkQuestion(context.question.id)
        context.survey.addQuestionWithConnectionProblems(context.question, {url: url.href, error: xhr.responseJSON?.error || thrownError})
        isReadyCallback({
          isFound: false
        })
      }
    })
  }
}

// Used to fetch a remote resource. Url argument can be interpolated
// getRemoteObjectProp('/misc/AIFAdrugs/$', {id}, 'ATCcode')
// getRemoteObjectProp('/misc/AIFAdrugs?w=(ATCcode,eq,$)', {ATCcode}, 'id')
// getRemoteObjectProp('$/aria/findOne?w=(ActivityCode,eq,$)~and(ActivityCategoryCode,eq,Exam)',{meta_clinical_data_base_url},{ActivityCode},{propName})
// getRemoteObjectProp('/misc/chapters/1','id')
const getRemoteObjectProp = function(args) {
  let url, params, prop
  url = args[0]
  prop = args[args.length-1]
  params = _.compact(_.slice(args,1,-1)).reverse()

  if (url &&
    prop &&
    params.length == (url.match(/\$/g)||[]).length &&
    this.survey.setupIsFinished && // prevent from running until survey setup is completed: this custom function dependes on some survey setup settings like waitingForNetworkQuestions and questionsWithConnectionProblems
    !this.survey.questionsWithConnectionProblems[this.question.id]
  ) {

    let currentIndex = url.indexOf("$")

    while (currentIndex != -1) {
      url = url.replace("$",params.pop())
      currentIndex = url.indexOf("$")
    }

    if (url.startsWith("/")) {
      url = window.location.origin + url
    }
    url = new URL(url)


  } else {
    url = undefined
  }

  var isReady = remoteObject => {
    if (remoteObject.isFound) {
      if (_.isArray(remoteObject["data"]) ) {
        switch(remoteObject["data"].length) {
        case 1:
          this.returnResult(remoteObject["data"][0][prop])
          break
        case 0:
          console.warn(`getRemoteObjectProp: no object returned by request: ${url.href}`)
          this.returnResult()
          break
        default:
          console.warn(`getRemoteObjectProp: more than one object returned by request: ${url.href}`)
          this.returnResult()
        }
      } else {
        this.returnResult(remoteObject["data"] && remoteObject["data"][prop])
      }
    } else {
      this.returnResult()
    }

  }

  return getRemoteObjectAjax(this, url, isReady)
}

// Used to fetch resources by id ONLY. OBSOLETE MUST NOT BE USED ANYMORE. USE getRemoteObjectProp
// getRemoteObjectProperty('/misc/AIFAdrugs', 35, 'ATCcode')
// getRemoteObjectProperty('/misc/AIFAdrugs', {drugId}, 'ATCcode')
const getRemoteObjectProperty = function(args) {
  let baseUrl, id, property;
  [baseUrl, id, property] = args

  let url
  if (baseUrl && id && property) { // all params must be well defined
    if (baseUrl.startsWith("/")) {
      baseUrl = window.location.origin + baseUrl
    }
    if (!baseUrl.endsWith("/")) {
      baseUrl += "/"
    }
    url = new URL(id, baseUrl)
  } else {
    url = undefined
  }

  var isReady = function(remoteObject) {
    this.returnResult(remoteObject["data"] && remoteObject["data"][property])
  }.bind(this)

  return getRemoteObjectAjax(this, url, isReady)
}


export { getRemoteObjectProp, getRemoteObjectProperty }
