import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, Alert } from 'react-bootstrap'

export default function ConnectionErrorsHeader({
  questions = {},
  detailsEnabled = false,
}) {
  const [showQuestions, setShowQuestions] = React.useState(false)

  if (!_.isEmpty(questions)) {
    return (
      <Alert variant="danger">
        <Alert.Heading>
          <i className="fa fa-exclamation-triangle" />
          {' '}
          Connection problem. Data cannot be changed or saved.
        </Alert.Heading>
        <p>
          Please try later or contact the support
        </p>
        {
        detailsEnabled
          ? (
            <div>
              <p>
                Some questions cannected to external services  are malfunctioning and may show missing or wrong answers. In order not to compromise saved data, the form is not editable.
              </p>
              <p>
                The following errors were returned from external services:
              </p>
              <ul>
                {
                  _.map(_.groupBy(questions, 'error'), (questions, error) => (
                    <li key={error}>
                      <strong>{error}</strong>
                      <Collapse in={showQuestions}>
                        <ul>
                          {
                            _.map(questions, (questionDetails, i) => (
                              <li key={i}>
                                {questionDetails.question.name}
                                {' '}
                                -
                                {' '}
                                <i>{questionDetails.url}</i>
                              </li>
                            )
                            )
                          }
                        </ul>
                      </Collapse>
                    </li>
                  ))
                }
              </ul>
              <a href="#" onClick={() => setShowQuestions(!showQuestions)}>
                {showQuestions ? 'hide' : 'show'}
                {' '}
                details about involved questions
              </a>
            </div>
            )
          : null
        }
      </Alert>
    )
  }
  return null
}

ConnectionErrorsHeader.propTypes = {
  detailsEnabled: PropTypes.bool,
  questions: PropTypes.object,
}
