import React from "react"
import PropTypes from "prop-types"
import * as SurveyCore from "survey-core"
import { Survey } from "survey-react-ui"
import addWidgets from "../../sjs_extensions/widgets"
import * as SurveyCustomFunctions from "../../sjs_extensions/custom_functions"
import * as SjsUtils from "../../sjs_extensions/sjs_utils"
import { create } from "jsondiffpatch"
import { markdownHandler } from "../utils"
import { RendererFactory } from 'survey-core'

export default class Form extends React.Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    data: PropTypes.object,
    originalData: PropTypes.object,
    externalCallbacks: PropTypes.object,
    onlyRead: PropTypes.bool,
    surveyAction: PropTypes.string,
    errors: PropTypes.array,
    handleChange: PropTypes.func,
    handleSave: PropTypes.func
  }

  static defaultProps = {
    onlyRead: false,
    data: {},
    originalData: {},
    externalCallbacks: {},
  }

  constructor (props) {
    super(props)

    // create a configured instance, match objects by id
    this.diffpatcher = create({
      objectHash: function(obj) {
        return obj.id
      }
    })

    this.resetSurvey(this.props.model)
  }

  updateSurveyAfterAction = () => {
    switch (this.props.surveyAction) {
    case "complete":
      this.survey.doComplete()
      break
    case "reset":
      // this.resetSurvey(this.props.model)
      break
    default:
    }
  }

  resetSurvey = (model) => {

    addWidgets(SurveyCore)

    // add custom functions
    _.forIn(SurveyCustomFunctions, (v,k) => SurveyCore.FunctionFactory.Instance.register(k, v))

    SurveyCore.StylesManager.applyTheme("bootstrap")

    SurveyCore.Serializer.addProperty("question", {
      name: "rootClassSuffix:string",
      default: ""
    })

    SurveyCore.Serializer.addProperty("panel", {
      name: "rootClassSuffix:string",
      default: ""
    })

    SurveyCore.ChoicesRestfull.onBeforeSendRequest = (choicesByUrl,options) => {
      options.request.setRequestHeader("X_REQUESTED_WITH","xmlhttprequest") // so that my server can recognize it as xhr https://github.com/surveyjs/survey-library/issues/3807
    }

    RendererFactory.Instance.registerRenderer(
      'dropdown',
      'reactselect',
      'sv-reactselect',
      true
    )

    RendererFactory.Instance.registerRenderer(
      'checkbox',
      'reactselect',
      'sv-reactselect',
      // true
    )

    console.debug(RendererFactory.Instance)

    const baseSjsModel = {
      clearInvisibleValues: "onHidden",
      requiredText: "* ",
      showNavigationButtons: 'none',
      showCompletedPage: false,
      showQuestionNumbers: "off",
      checkErrorsMode: "onValueChanged",
      mode: this.props.onlyRead ? "display" : "edit",
      questionDescriptionLocation: "underInput"
    }

    const survey = new SurveyCore.Model(_.merge(baseSjsModel, model))

    this.addCallbacks(survey)

    this.headerErrors = []
    this.survey = survey
  }

  addCallbacks = (survey) => {
    // default behaviour
    survey.onValueChanged.add(this.valueChanged)
    survey.onComplete.add(this.completed)

    survey.onUpdateQuestionCssClasses.add(function (survey, options) {
      options.cssClasses.mainRoot += ` cgp_qstn_${options.question.getType()} ${options.question.rootClassSuffix || ""}`
    })
    survey.onUpdatePanelCssClasses.add(function (survey, options) {
      options.cssClasses.panel.container += ` ${options.panel.rootClassSuffix || ""}`
    })
    survey.onTextMarkdown.add(markdownHandler)

    // callback to prevent sjs from deleting the associaton id (because it is invisible) when an association get updated (https://github.com/surveyjs/surveyjs/issues/1542)
    survey.onValueChanging.add((sender, options) => {
      if (this._isAnAssociationField(options.name)) { // a change happened in the associations nested attributes
        if (options.value && options.oldValue && options.oldValue.length == options.value.length) { // it's not an addition or deletion: its an association update
          _.forEach(options.oldValue, (row,index) => {
            if (row.id) {  // keep the old id if it exists
              options.value[index].id = row.id
            }
          })
        }
        // console.debug('DATA CHANGE FROM:');
        // console.debug(options.oldValue);
        // console.debug('TO');
        // console.debug(options.value);
      }
    })
  }

  valueChanged = (survey, options) => {
    console.debug(`form changed at ${options.name}`)
    this.props.handleChange(this.survey.data)
  }

  // https://stackoverflow.com/questions/36691125/lodash-isblank
  _isBlankField(value) {
    return _.isEmpty(value) && !_.isNumber(value) || _.isNaN(value)
  }

  _isAnAssociationField(fieldName) {
    return _.endsWith(fieldName, "_attributes")
  }

  _getSurveyDataWithDeletions() {
    const dataToSend = _.cloneDeep(this.survey.data)

    // online diffpatcher https://benjamine.github.io/jsondiffpatch/demo/index.html
    let differences =  this.diffpatcher.diff(this.props.originalData,this.survey.data)
    // console.debug(this.props.originalData);
    // console.debug(this.survey.data);
    // console.debug(differences);

    _.forOwn(differences, (value, fieldName) => {
      if (this._isAnAssociationField(fieldName)) { // it's a nested association
        if (value._t == "a") { // differences involve the array elements which means one or more associations
          _.forOwn(_.omitBy(value, (v,k) => k == "_t"), (v,k) => { // for all position differences
            if (_.startsWith(k,"_")) { // the association has been dropped
              if (v[1] == 0) {
                dataToSend[fieldName].push({ _destroy: 1, id: v[0].id })
              }
            } else {
              if (!_.isArray(v)) { // the association has been modified
                let i = parseInt(k)
                _.forOwn(v, (v,attr) => {
                  if (_.isEqual(v.slice(1),[0,0])) {
                    dataToSend[fieldName][i][attr] = null
                  }
                })
              }
            }
          })
        } else {
          if (value[1] == 0) { // all associations were dropped
            dataToSend[fieldName] = []
            _.forEach(value[0], (v) => {dataToSend[fieldName].push({ _destroy: 1, id: v.id })})
          }
        }
      } else {
        if (value._t != "a" && _.isEqual(value.slice(1),[0,0])) { // it's a deletion not in an array
          let oldValue = value[0]
          let newValue

          if (_.isArray(oldValue)) {
            newValue = []
          } else {
            newValue = null
          }
          dataToSend[fieldName] = newValue
        }
      }
    })

    // console.debug('survey data with deletions:');
    // console.debug(JSON.stringify(dataToSend,null,2));

    return dataToSend
  }

  completed = (survey) => {
    survey.clear(false, false)
    if (!survey.checkIsCurrentPageHasErrors()) {
      this.props.handleSave(this._getSurveyDataWithDeletions())
    }
  }

  render() {
    console.debug("RERENDERING FORM")

    this.updateSurveyAfterAction()

    let errors = []
    if (this.props.errors.length) {
      errors = <div className="alert alert-danger alert-dismissible">
        <button className="close" data-dismiss="alert">x</button>
        <h5><i className="icon fa fa-ban" />Errors:</h5>
        <ul>
          {this.props.errors.map((error, i) => <li key={i}>{error}</li>)}
        </ul>
      </div>
    }
    return (
      <div>
        {errors}
        <div className="form-inputs">
          <Survey
            model={this.survey}
            data={this.props.data}
            css={SjsUtils.getCustomizedBootstrapCss()}
          />
        </div>
      </div>
    )
  }
}
