import componentTemplateJSON from './labresults.json'
import labresultsDataJSON from './labresults_data.json'
import * as SurveyCore from 'survey-core'
import { PropertyGridEditorCollection } from 'survey-creator-react'

export default {
  name: 'labresults',
  title: 'labresults Test Component',
  defaultQuestionTitle: 'Lab Tests',
  elementsJSON: componentTemplateJSON,
  onInit() {
    // console.debug(json)
    SurveyCore.Serializer.addProperty('labresults', {
      name: 'fromDate',
      type: 'date',
      default: moment().format('YYYY-MM-DD'),
      category: 'general',
    })
    PropertyGridEditorCollection.register({
      fit: function (prop) {
        return prop.type === 'date'
      },
      getJSON: function (obj, prop, options) {
        return { type: 'text' }
      },
      validateValue: function (obj, question, prop, value) {
        if (!value) return ''
        const valid = /^(\d{4}-\d{2}-\d{2})?$/.test(value)
        return !valid ? 'Incorrect date format: it should be \'YYYY-MM-DD\'' : ''
      },
    })
    SurveyCore.Serializer.addProperty('labresults', {
      name: 'manualValWin',
      type: 'boolean',
      default: false,
      category: 'general',
    })
    SurveyCore.Serializer.addProperty('labresults', {
      name: 'examIds',
      type: 'multiplevalues',
      choices: _.keys(labresultsDataJSON),
      category: 'general',
    })
    SurveyCore.Serializer.addProperty('labresults', {
      name: 'includeCommentForExamIds',
      type: 'multiplevalues',
      choices: _.keys(labresultsDataJSON),
      category: 'general',
    })
    SurveyCore.Serializer.addProperty('labresults', {
      name: 'includeRefRangeForExamIds',
      type: 'multiplevalues',
      choices: _.keys(labresultsDataJSON),
      category: 'general',
    })
  },
  onLoaded(question) {
    this.updateChoicesByUrl(question)
    this.setExamQuestions(question)
  },

  onPropertyChanged(question, propertyName, newValue) {
    if (_.includes(['fromDate', 'manualValWin', 'examIds', 'includeCommentForExamIds', 'includeRefRangeForExamIds'], propertyName)) {
      this.updateChoicesByUrl(question)
      this.setExamQuestions(question)
    }
  },

  updateChoicesByUrl(question, fromDate, examIds) {
    const templateUrl = question.contentPanel.getQuestionByName('date_lv').choicesByUrl.getPropertyValue('url')

    fromDate ||= question.getPropertyValue('fromDate')
    examIds ||= question.getPropertyValue('examIds')
    examIds = this.removeUnknownExamIds(examIds).map(eId => eId.split('-')[0])

    const url = templateUrl.replace('from=', `from=${fromDate}`).replace('code=', `code=${_.map(examIds, examId => examId + '@')}`)

    question.contentPanel.getQuestionByName('date_lv').choicesByUrl.setPropertyValue('url', url)
  },

  setExamQuestions(question, examIds, includeCommentForExamIds, includeRefRangeForExamIds) {
    examIds ||= question.getPropertyValue('examIds')
    examIds = this.removeUnknownExamIds(examIds)

    includeCommentForExamIds ||= question.getPropertyValue('includeCommentForExamIds')
    includeCommentForExamIds = this.removeUnknownExamIds(includeCommentForExamIds)

    includeRefRangeForExamIds ||= question.getPropertyValue('includeRefRangeForExamIds')
    includeRefRangeForExamIds = this.removeUnknownExamIds(includeRefRangeForExamIds)

    // filter out not involved exams
    question.contentPanel.elements.slice(5).forEach(e => !_.some(examIds, eId => _.startsWith(e.name, `${eId}_`)) && e.removeFromParent())

    // filter out unwanted ref_range
    question.contentPanel.elements.filter(e => _.endsWith(e.name, '_ref_range')).forEach(e => !_.some(includeRefRangeForExamIds, eId => _.startsWith(e.name, `${eId}_`)) && e.removeFromParent())

    // filter out unwanted comment
    question.contentPanel.elements.filter(e => _.endsWith(e.name, '_comment')).forEach(e => !_.some(includeCommentForExamIds, eId => _.startsWith(e.name, `${eId}_`)) && e.removeFromParent())

    // reorder exam elements
    examIds.forEach(eId => question.contentPanel.elements.slice(5).filter(e => _.startsWith(e.name, eId)).forEach((e) => {
      e.moveTo(question.contentPanel, question.contentPanel.elements.length)
      if (_.endsWith(e.name, '_mv')) {
        e.setPropertyValue('startWithNewLine', false)
      }
    }))

    // se final value expression
    examIds.map((examId) => {
      let finalValueElement = _.find(question.contentPanel.elements, e => e.name == `${examId}_v`, 1)
      finalValueElement.expression = this.getQVExpression(question, examId)
    })
  },

  removeUnknownExamIds(examIds) {
    return _.intersection(examIds, _.keys(labresultsDataJSON))
  },

  getQVExpression(question, examId) {
    return question.getPropertyValue('manualValWin')
      ? `iif({composite.${examId}_mv} notempty, {composite.${examId}_mv}, {composite.${examId}_lv})`
      : `iif({composite.${examId}_lv} notempty, {composite.${examId}_lv}, {composite.${examId}_mv})`
  },

}
