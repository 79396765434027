// usage examples:
// dateDiff("1977-05-26", "2020-08-12") => 43
// dateDiff("1977-05-26", "2020-08-45546") => undefined
// dateDiff("1977-05-26", "2020-") => undefined
// dateDiff("1977-05-26") => undefined
// dateDiff("1977-05-26", "2020-08-12", "month") => 518
// dateDiff("1977-05-26", "2020-08-12", "day") => 15784
// dateDiff("1977-05-26", "2020-08-12", "year") => 43
// dateDiff("1977-05-26", "2020-08") => 43
// dateDiff("1977-05-26", "2020") => 42
// dateDiff("1977", "2020") => 43
// dateDiff("1977", "2020", "day") => null
const dateDiff = function (args) {
  const allPrecisions = ['year', 'month', 'day'],
    re = /^(?<year>\d{4})(-(?<month>(0?[0-9])|((1)[0-2])))?(-(?<day>[1-9]|[0-2][0-9]|(3)[0-1]))?$/,
    date1 = args[0] && args[0].toString().trimEnd(),
    date2 = args[1] && args[1].toString().trimEnd(),
    date1re = date1 && date1.match(re),
    date2re = date2 && date2.match(re),
    wantedPrecision = args[2] || 'year'

  if (!date1re || !date2re) {
    return
  }

  const precisonIndexOf = (datere) => {
    const g = datere && datere.groups
    return (g.day && 2) || (g.month && 1) || (g.year && 0)
  }

  const minRoundIndex = Math.min(
    precisonIndexOf(date1re),
    precisonIndexOf(date2re)
  )

  if (allPrecisions.indexOf(wantedPrecision) > minRoundIndex) {
    return null
  }

  let d1 = moment(new Date(date1))
  let d2 = moment(new Date(date2))

  return d2.diff(d1, wantedPrecision)
}

// derived from dateDiff for Russo group
// dateDiff2("1977-05-26", "2020-08-12") => "43"
// dateDiff2("1977-05-26", "2020-08-45546") => undefined
// dateDiff2("1977-05-26", "2020-") => undefined
// dateDiff2("1977-05-26") => undefined
// dateDiff2("1977-05-26", "2020-08-12", "month") => "43.2"
// dateDiff2("1977-05-26", "2020-08-12", "day") => "43.2.17"
// dateDiff2("1977-05-26", "2020-08-12", "year") => "43"
// dateDiff2("1977-05-26", "2020-08") => "43"
// dateDiff2("1977-05-26", "2020") => "42"
// dateDiff2("1977", "2020") => "43"
// dateDiff2("1977", "2020", "day") => null
const dateDiff2 = function (args) {
  const allPrecisions = ['year', 'month', 'day'],
    re = /^(?<year>\d{4})(-(?<month>(0?[0-9])|((1)[0-2])))?(-(?<day>[1-9]|[0-2][0-9]|(3)[0-1]))?$/,
    date1 = args[0] && args[0].toString().trimEnd(),
    date2 = args[1] && args[1].toString().trimEnd(),
    date1re = date1 && date1.match(re),
    date2re = date2 && date2.match(re),
    wantedPrecision = args[2] || 'year'

  if (!date1re || !date2re) {
    return
  }

  const precisonIndexOf = (datere) => {
    const g = datere && datere.groups
    return (g.day && 2) || (g.month && 1) || (g.year && 0)
  }

  const minAvailablePrecisionIndex = Math.min(
    precisonIndexOf(date1re),
    precisonIndexOf(date2re)
  )
  const wantedPrecisionIndex = allPrecisions.indexOf(wantedPrecision)
  if (wantedPrecisionIndex > minAvailablePrecisionIndex) {
    return null
  }
  const actualPrecisions = allPrecisions.slice(0, wantedPrecisionIndex + 1)

  let d1 = moment(new Date(date1))
  let d2 = moment(new Date(date2))

  return actualPrecisions
    .reduce((acc, p) => {
      var diff = d2.diff(d1, p)
      d1.add(diff, p)
      return acc.concat(diff)
    }, [])
    .join('.')
}

// addToDate("1977-05-26", 3, "days") => '1977-05-29'
// addToDate("1977-05-12", -3, "months") => '1977-02-12'
// addToDate("1977-05-31", -3, "months") => '1977-02-28'
// addToDate("1977-05-26", 13, "years") => '1990-05-26'
const addToDate = function (args) {
  const
    allowedUnits = ['years', 'months', 'days'],
    reCompleteDate = /^(?<year>\d{4})-(?<month>(0?[0-9])|((1)[0-2]))-(?<day>[1-9]|[0-2][0-9]|(3)[0-1])$/,
    date = args[0] && args[0].trimEnd(),
    amount = args[1],
    unit = args[2]?.trimEnd() || 'days'

  if (!date?.match(reCompleteDate) || !_.isInteger(amount) || !allowedUnits.includes(unit)) {
    return
  }

  let d = moment(new Date(date))

  return d.add(amount, unit).format('YYYY-MM-DD')
}

// function to retrieve property from object already loaded in other dropdown question (targetQuestion)
// without canAdd and with one of the props:
//
// * choicesByUrl (needs property choicesByUrl.attachOriginalItems = true)
// * lazyChoicesByUrl
//
// usage examples:
// getSelectedChoiceProp('country','capital')
// getSelectedChoiceProp('panel.country','capital')
// getSelectedChoiceProp('row.country','capital')
const getSelectedChoiceProp = function (args) {
  let targetQuestionName = args[0],
    prop = args[1],
    targetQuestion

  try {
    if (!targetQuestionName || !prop) { throw 'targetQuestionName and prop args must be defined' }

    let targetQuestionNameArray = targetQuestionName.split('.')

    if (targetQuestionNameArray.length > 1) { // the targetQuestion is in the same panel or row
      switch (targetQuestionNameArray[0]) {
        case 'row': // it's inside a matrixdynamic cell
          if (this.row == undefined) throw 'row not found'
          targetQuestion = this.row.cells.map(c => c.question).find(e => e.name == targetQuestionNameArray[1])
          break
        case 'composite': // it's inside a composite question // this may not work for more complex composite question that contain panels or paneldynamic etc
          if (this.question.data == undefined) throw 'composite not found'
          targetQuestion = this.question.data.getQuestionByName(targetQuestionNameArray[1])
          break
        case 'panel': // it's inside a paneldynamic panel
          if (this.question.parent == undefined) throw 'panel not found'
          targetQuestion = this.question.parent.getQuestionByName(targetQuestionNameArray[1])
          break
        default:
          throw ('prefix must be row, panel or composite')
      }
    }
    else { // the targetQuestion is at root level
      targetQuestion = this.survey.getQuestionByName(targetQuestionName)
    }
    if (targetQuestion == undefined) throw ('targetQuestion not found')
  }
  catch (e) {
    console.error(`getSelectedChoiceProp error in question ${this.question.name}`)
    throw (e)
  }

  // check targetQuestion requirements
  if (targetQuestion.getType() != 'dropdown') return
  if (!targetQuestion.choicesByUrl && !targetQuestion.lazyChoicesByUrl) return
  if (targetQuestion.canAdd) return

  if (targetQuestion.value) {
    let selectedItem = targetQuestion.visibleChoices.find(c => targetQuestion.value == c.value)
    if (selectedItem && selectedItem.originalItem) {
      return _.get(selectedItem.originalItem, prop)
    }
    // the assumption here is that if targetQuestion has a value than the value ALREADY stored in question is OK.
    // Removing the below line will cause the question remain empty on initialization because targetQuestion.visibleChoices is empty and will not update targetQuestion value when it will be ready
    return this.question.value
  }
  return undefined
}

// function to retrieve a question value frome the last item in dynamic question
//
// usage examples:
// getValueFromLastItem({list}, 'item')
const getValueFromLastItem = function (args) {
  if (_.some(args, _.isNil)) return
  let targetDynamicQuestionValue = args[0],
    targetQuestion = args[1]

  if (!!targetDynamicQuestionValue && Array.isArray(targetDynamicQuestionValue) && targetDynamicQuestionValue.length) {
    return targetDynamicQuestionValue[targetDynamicQuestionValue.length - 1][targetQuestion]
  }
}

// function to retrieve a question value from a paneldynamic panel identified by key
//
// usage examples:
// getPropFromDynamicItem('tumors', '2000-03-01', 'name')
const getPropFromDynamicItem = function (args) {
  if (_.some(args, _.isNil)) return
  let dynamicQuestionName = args[0],
    itemKey = args[1],
    itemQuestionName = args[2]

  const dynamicQuestion = this.survey.getQuestionByName(dynamicQuestionName)

  if (dynamicQuestion && _.isArray(dynamicQuestion.value) && dynamicQuestion.value.length) {
    const item = _.find(dynamicQuestion.value, e => e[dynamicQuestion.keyName] == itemKey)
    if (item) {
      return item[itemQuestionName]
    }
  }
}

const albiScore = function (args) {
  const bilirubin = args[0],
    albumin = args[1]

  if (_.isNil(bilirubin) || _.isNil(albumin)) return

  return Math.log10(bilirubin * 17.1) * 0.66 + albumin * -0.085
}

const tenYearsSurvival = function (args) {
  if (_.isNil(args[0])) return
  return _.round(Math.pow(0.983, Math.pow(Math.E, args[0] * 0.9)), 2) * 100
}

const log10 = function (args) {
  if (args.length != 1 || !_.isNumber(args[0]) || args[0] <= 0) {
    return
  }
  return Math.log10(args[0])
}

const log = function (args) {
  if (args.length != 1 || !_.isNumber(args[0]) || args[0] <= 0) {
    return
  }
  return Math.log(args[0])
}

const randInt = function (args) {
  if (args.length == 1 && _.isNumber(args[0])) {
    return Math.random().toString().slice(2, 2 + args[0])
  }
  else {
    return Math.random().toString().slice(2, 7)
  }
}

const testCustomFunc = function (args) {
  if (args.length != 2) {
    return
  }
  return args[0] + args[1]
}

const todayDate = function () {
  return moment().format(moment.DATE)
}

const dateWithoutDash = function (args) {
  const date = args[0]

  if (_.isNil(date)) return

  return date.replace(/-/g, '')
}

const ccsi = function (args) {
  if (_.some(args, _.isNil))
    return
  else
    return _.sum(args)
}

function framinghamM(Age, TotalCholesterol, HDLCholesterol, SystolicBP, Smoker, TreatedBP) {
  const betaAge = 52.00961
  const betaTotalChol = 20.014077
  const betaHDLChol = -0.905964
  const betaSystolicBP = 1.305784
  const betaSmoker = 12.096316
  const betaTreatedBP = 0.241549
  const betaAgeChol = -4.605038
  const betaAgeSmoker = -2.84367
  const betaAgeAge = -2.93323

  const logAge = Math.log(Age)
  const logTotalChol = Math.log(TotalCholesterol)
  const logHDLChol = Math.log(HDLCholesterol)
  const logSystolicBP = Math.log(SystolicBP)
  const logAgeChol = logAge * logTotalChol
  const logAgeSmoker = Age > 70 ? Math.log(70) * Smoker : logAge * Smoker
  const logAgeLogAge = logAge * logAge

  const result = 1 - Math.pow(0.9402, Math.exp(
    betaAge * logAge
    + betaTotalChol * logTotalChol
    + betaHDLChol * logHDLChol
    + betaSystolicBP * logSystolicBP
    + betaSmoker * Smoker
    + betaTreatedBP * TreatedBP
    + betaAgeChol * logAgeChol
    + betaAgeSmoker * logAgeSmoker
    + betaAgeAge * logAgeLogAge
    - 172.300168
  ))

  return result * 100 // Result is usually expressed as a percentage
}

function framinghamF(Age, TotalCholesterol, HDLCholesterol, SystolicBP, Smoker, TreatedBP) {
  const betaAge = 31.764001
  const betaTotalChol = 22.465206
  const betaHDLChol = -1.187731
  const betaSystolicBP = 2.552905
  const betaSmoker = 13.07543
  const betaTreatedBP = 0.420251

  const betaAgeChol = -5.060998
  const betaAgeSmoker = -2.996945

  const logAge = Math.log(Age)
  const logTotalChol = Math.log(TotalCholesterol)
  const logHDLChol = Math.log(HDLCholesterol)
  const logSystolicBP = Math.log(SystolicBP)

  const logAgeChol = logAge * logTotalChol
  const logAgeSmoker = Age > 78 ? Math.log(78) * Smoker : logAge * Smoker

  const result = 1 - Math.pow(0.98767, Math.exp(
    betaAge * logAge
    + betaTotalChol * logTotalChol
    + betaHDLChol * logHDLChol
    + betaSystolicBP * logSystolicBP
    + betaSmoker * Smoker
    + betaTreatedBP * TreatedBP
    + betaAgeChol * logAgeChol
    + betaAgeSmoker * logAgeSmoker
    - 146.5933061
  ))

  return result * 100 // Result is usually expressed as a percentage
}

function framingham(args) {
  if (_.some(args, _.isNil)) return

  let Sex, Age, TotalCholesterol, HDLCholesterol, SystolicBP, Smoker, TreatedBP

  [Sex, Age, TotalCholesterol, HDLCholesterol, SystolicBP, Smoker, TreatedBP] = args

  switch (Sex) {
    case 'm':
      return framinghamM(Age, TotalCholesterol, HDLCholesterol, SystolicBP, Smoker, TreatedBP)
    case 'f':
      return framinghamF(Age, TotalCholesterol, HDLCholesterol, SystolicBP, Smoker, TreatedBP)
  }
}

function getInArrayParams(params) {
  if (params.length != 2) return null
  let arr = params[0]
  if (!arr) return null
  if (!Array.isArray(arr) && !Array.isArray(Object.keys(arr))) return null
  let name = params[1]
  if (typeof name !== 'string' && !(name instanceof String)) return null
  return { data: arr, name: name }
}

function calcInArray(
  params,
  func
) {
  let v = getInArrayParams(params)
  if (!v) return undefined
  let res = undefined
  if (Array.isArray(v.data)) {
    for (let i = 0; i < v.data.length; i++) {
      let item = v.data[i]
      if (!!item && item[v.name]) {
        res = func(res, item[v.name])
      }
    }
  }
  else {
    for (let key in v.data) {
      let item = v.data[key]
      if (!!item && item[v.name]) {
        res = func(res, item[v.name])
      }
    }
  }
  return res
}

function concatInArray(params) {
  let sep = params[2] || ', ',
    res = calcInArray(params.splice(0, 2), function (res, val) {
      return _.join(_.compact([res, val]), sep)
    })
  return res !== undefined ? res : ''
}

function maxDateInArray(params) {
  return extremeDateInArray(params, 'max')
}

function minDateInArray(params) {
  return extremeDateInArray(params, 'min')
}

function extremeDateInArray(params, extreme) {
  let comparisonFunc
  switch (extreme) {
    case 'max':
      comparisonFunc = (d1, d2) => d1.isAfter(d2)
      break
    case 'min':
      comparisonFunc = (d1, d2) => d1.isBefore(d2)
      break
  }

  let re = /^(?<year>\d{4})-(?<month>(0?[0-9])|((1)[0-2]))-(?<day>[1-9]|[0-2][0-9]|(3)[0-1])$/,
    res = calcInArray(params, function (res, val) {
      let date1String = res && res.toString().trimEnd(),
        date2String = val && val.toString().trimEnd(),
        date1 = date1String && date1String.match(re) && moment(new Date(date1String)),
        date2 = date2String && date2String.match(re) && moment(new Date(date2String))

      if (date1 && date1.isValid()) {
        if (date2 && date2.isValid()) {
          return comparisonFunc(date1, date2) ? date1.format('YYYY-MM-DD') : date2.format('YYYY-MM-DD')
        }
        else {
          return date1.format('YYYY-MM-DD')
        }
      }
      else {
        if (date2 && date2.isValid()) {
          return date2.format('YYYY-MM-DD')
        }
        else {
          return
        }
      }
    })
  return res
}

export {
  dateDiff,
  dateDiff2,
  addToDate,
  getSelectedChoiceProp,
  albiScore,
  tenYearsSurvival,
  getValueFromLastItem,
  getPropFromDynamicItem,
  log10,
  log,
  testCustomFunc,
  randInt,
  todayDate,
  dateWithoutDash,
  ccsi,
  framingham,
  concatInArray,
  maxDateInArray,
  minDateInArray,
}
