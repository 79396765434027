import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Card, Container, Row, Col, Table } from "react-bootstrap"
import LiftUpStepForm from "./lift_up_step_form"
import styled from "styled-components"
import { LiftUpHistory } from "../utils"
import {bootstrapSelectStyle} from "../../utils"
import Select from "react-select"

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
  #steps {
    overflow: auto;
    ul {
      padding-inline-start: 0;
      li {
        list-style-type:none;
      }
    }
  }
`

function LiftUpManager(props) {

  const [showNewStepForm, setShowNewStepForm] = useState(false)

  const addNewStep = newStep => {
    setShowNewStepForm(false)
    props.liftUpHistory.addStep(newStep)
    props.handleLiftUpHistoryChange(props.liftUpHistory.data)
  }

  const removeStep = i => {
    props.liftUpHistory.removeStepAt(i)
    props.handleLiftUpHistoryChange(props.liftUpHistory.data)
  }

  const changeStepAfterField = (step, newAfterField) => {
    props.liftUpHistory.replaceStepAt(step.idx,{...step.data, afterField: newAfterField})
    props.handleLiftUpHistoryChange(props.liftUpHistory.data)
  }

  const handleCancel = _ => {
    setShowNewStepForm(false)
  }

  const visibleParentFieldsAt = step => {
    return _.map(_.omitBy(props.workbook[step.sheetId].parent.fields, f => f.type == "liftedUp" && f.liftedUpStep >= step.idx), "name")
  }

  const visibleParentFieldsSelectOptionsAt = step => {
    return _.map(visibleParentFieldsAt(step), fieldName => ({ "value": fieldName, "label": fieldName}))
  }

  useEffect(() => {
    handleCancel() // reset form if something change in workbook
  }, [props.workbook])

  const typeSpecificCells = step => {
    switch(step.type) {
    case "agg":
      return <>
        <td>{`AGG ${step.formula}`}</td>
        <td>{step.sheetId}.<strong>{step.field}</strong></td>
        <td>{props.workbook[step.sheetId].parent.id}.<strong>{step.name}</strong></td>
      </>
    case "exp":
      return <>
        <td>{"EXP"}</td>
        <td><ul>{step.fields.map(f => <li key={f}>{step.sheetId}.<strong>{f}</strong></li>)}</ul> </td>
        <td><ul>{step.fields.map(f => <li key={f}>{props.workbook[step.sheetId].parent.id}.<strong>{`${step.prefix}${props.workbook[step.sheetId].isMadeOfScalars ? "" : f.concat("_")}*`}</strong></li>)}</ul></td>
      </>
    }
  }

  return <Styles>
    <Card bsPrefix={`card card-outline card-secondary bg-light ${props.liftUpHistory.steps.length ? null : "collapsed-card"}`}>
      <Card.Header>
        <Card.Title>
          <strong>Fields LiftUp</strong>
        </Card.Title>
        <div className="card-tools">
          <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className={`fas ${props.liftUpHistory.steps.length ? "fa-minus" : "fa-plus"}`}></i></button>
        </div>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col lg={showNewStepForm ? 9 : 12} id="steps">
              <Table bsPrefix="table table-sm bg-white" >
                <thead>
                  <tr>
                    <th>step #</th>
                    <th>type</th>
                    <th>from</th>
                    <th>to</th>
                    <th>after field</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.liftUpHistory.steps.map((step, i) =>
                      <tr key={i}>
                        <td>{i+1}</td>
                        {typeSpecificCells(step)}
                        <td>
                          <Select
                            isClearable
                            styles={bootstrapSelectStyle}
                            options={visibleParentFieldsSelectOptionsAt(step)}
                            value={{"value": step.afterField, "label": step.afterField || <i className="text-muted">at the end</i>}}
                            isMulti={false}
                            onChange={o => changeStepAfterField(step, o?.value)}
                            menuPosition="fixed"
                          />
                        </td>
                        <td>
                          <Button size="sm" disabled={step.isInUse() || props.disabled} variant='link' onClick={ _ => removeStep(i) }>
                            <i className="fas fa-trash text-danger"></i>
                          </Button>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
              <Button disabled={props.disabled} size="sm" variant='primary' onClick={ _ => setShowNewStepForm(true) }>
                <i className="fas fa-plus"></i> New step
              </Button>
            </Col>
            {
              showNewStepForm ?
                <Col lg={3} id="step-form">
                  <LiftUpStepForm
                    workbook={props.workbook}
                    step={props.liftUpHistory[showNewStepForm]}
                    handleSubmit={addNewStep}
                    handleCancel={handleCancel}
                    fieldNameRegexp={props.fieldNameRegexp}
                  />
                </Col>
                :
                null
            }
          </Row>
        </Container>
      </Card.Body>
    </Card>
  </Styles>
}

LiftUpManager.propTypes = {
  workbook: PropTypes.object.isRequired,
  liftUpHistory: PropTypes.instanceOf(LiftUpHistory),
  handleLiftUpHistoryChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fieldNameRegexp: PropTypes.instanceOf(RegExp).isRequired
}

const memoizedLiftUpManager = React.memo(LiftUpManager)
memoizedLiftUpManager.displayName = "LiftUpManager"

export default memoizedLiftUpManager
