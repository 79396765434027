import React from "react"
import * as ReactDOMServer from "react-dom/server"
import PropTypes from "prop-types"
import { Modal, Button, Table } from "react-bootstrap"
import styled from "styled-components"
import { exportHtml } from "./project_data/utils"

const styleString = `
table {
  font-family: Monospace;
  font-size: 12px;
}
table tbody {
  white-space: nowrap;
}
table tbody tr.legend-section {
  font-weight: bold;
}
table tbody tr .legend-display-type {
  text-transform: uppercase;
}
table tbody tr.page {
  background-color: lightblue;
}
table tbody tr.panel {
  background-color: lightgreen;
}
table tbody tr.multiple-panels {
  background-color: limegreen;
}
table tbody tr.expression .legend-display-type {
  background-color: gold;
  padding: 2px;
}
table tbody tr td {
  border: 1px solid #dee2e6;
  padding: 0.3rem;
  vertical-align: top;
}
table tbody tr td ul {
  padding-left: 20px;
  margin-bottom: unset;
}
`

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
// avoid flat checkboxes indentation
const Styles = styled.div`
  ${styleString}
`

function CollectorLegendModal({
  collector,
  handleClose,
}) {

  let styledTable

  if (collector) {
    styledTable = <Styles>
      <Table
        size="sm"
        hover
        bordered
      >
        <thead>
          <tr className="bg-secondary">
            <th>name</th>
            <th>title</th>
            <th>choices  (value | label)</th>
            <th>expression</th>
          </tr>
        </thead>
        <tbody>
          {
            collector.legend.map((item, i) =>
              <tr key={i} className={_.includes(["page","panel","matrixdynamic","paneldynamic"], item.type) ? `legend-section ${item.displayType}` : item.displayType }>
                <td>{" ⋅ ".repeat(item.depth)}{item.name} <span className='legend-display-type'> {item.displayType ? `[${item.displayType}]` : null}</span></td>
                <td>{" ⋅ ".repeat(item.depth)}{item.title} <span className='legend-display-type'> {item.displayType ? `[${item.displayType}]` : null}</span></td>
                <td>
                  {
                    item.choices && _.isArray(item.choices) ?
                      <ul>
                        {
                          item.choices.map((item, i) =>
                            <li key={i}>
                              {
                                _.isPlainObject(item) ?
                                  `${item.value} | ${item.text || item.value}`
                                  :
                                  item
                              }
                            </li>
                          )
                        }
                      </ul>
                      :
                      item.choices
                  }
                </td>
                <td>
                  {
                    item.expression
                  }
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </Styles>
  }

  const download =  () => {
    const
      html = ReactDOMServer.renderToStaticMarkup(styledTable)

    exportHtml({
      bodyContent: html,
      style: styleString,
      title: collector?.title
    })
  }

  const buttons = <>
    <Button variant="secondary" onClick={handleClose}>
      close
    </Button>
    <Button variant="primary" onClick={download}>
      <i className="fas fa-download"/> download
    </Button>
  </>

  return (
    <Modal
      show={!!collector}
      dialogClassName="modal-90w"
      scrollable={true}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{collector?.title} LEGEND</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {styledTable}
      </Modal.Body>

      <Modal.Footer>
        {buttons}
      </Modal.Footer>
    </Modal>
  )
}

CollectorLegendModal.propTypes = {
  collector: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
}
export default CollectorLegendModal
