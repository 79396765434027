import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { Modal, Button } from "react-bootstrap"
import { xFetch, WithSpinner } from "./utils"

function RemoveFromCohortDialog(props) {

  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const handleDelete = () =>
    xFetch(Routes.remove_member_cohort_path(props.cid), {
      method: "PATCH",
      headers: { "X-will-redirect": true },
      body: JSON.stringify({
        pid: props.pid
      })
    })
      .then(data => {
        window.location.reload()
      })
      .catch(error => {
        toastr.error(error.msgs.join("<br>"))
      })

  // https://www.robinwieruch.de/react-hooks-fetch-data/
  useEffect(() => {
    const fetchData = async () => {
      const result = await xFetch(Routes.before_remove_member_cohort_path(props.cid), {
        query: {
          pid: props.pid
        }
      })
      setData(result)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const isInvolvedInSomeProjects = !_.isEmpty(data?.participants)
  const hasSomeContents = isInvolvedInSomeProjects && _.some(data?.participants, p => !_.isEmpty(p.contents))

  const content = data &&
    <div>
      {
        isInvolvedInSomeProjects ?
          <div>
            <p>
              Membership in the cohort determines participation in the following projects (any filled collectors are also mentioned):
            </p>
            <ul>
              {
                data.participants.map(p =>
                  <li key={p.project.name}>
                    <i className="fa fa-book" /> {p.project.name}
                    { p.participantId ? ` (partID: ${p.participantId})` : null}
                    <ul>
                      {
                        p.contents.map((cc,i) => <li key={i}><i className="fa fa-file-invoice" /> <a href={cc.path} target="_blank" rel="noreferrer">{cc.collector}</a></li>)
                      }
                    </ul>
                  </li>
                )
              }
            </ul>
            <p>
              Hence, by removing this membership, you will also delete the participation to all the above projects.
              <br/><br/>
              However:
              <br/><br/>
              <i><i className="fas fa-exclamation-triangle mr-1"></i>Data present in filled collectors will not be deleted. If you want to remove the collector data, please, do that manually.</i><br/>
              {
                hasSomeContents ?
                  <i><i className="fas fa-exclamation-triangle mr-1"></i>{`The person ${data.person} will not be deleted. Only cohort membership is removed.`}</i>
                  :
                  null
              }
              <br/><br/>
              <b>Are you sure?</b>
            </p>
          </div>
          :
          `You are about to remove membership of ${data.person} to the cohort ${data.cohort}. Are you sure?`
      }

    </div>

  return (
    <Modal
      show={!_.isUndefined(props.pid) && !_.isUndefined(props.cid)}
      onHide={() => props.handleClose()}
      className="text-danger"
    >
      <Modal.Header closeButton>
        <Modal.Title>Remove member {data?.person} from cohort {data?.cohort}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WithSpinner isLoading={isLoading}>
          {content}
        </WithSpinner>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.handleClose()}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete} disabled={isLoading}>
          Yes I am sure
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

RemoveFromCohortDialog.propTypes = {
  pid: PropTypes.string,
  cid: PropTypes.number,
  handleClose: PropTypes.func.isRequired
}
export default RemoveFromCohortDialog
