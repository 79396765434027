import React from "react"
import PropTypes from "prop-types"
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap"
import AddVersionModal from "./add_version_modal"

export default class Versioner extends React.PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      isShowingVersionModal: false
    }
  }

  static propTypes = {
    handleAction: PropTypes.func.isRequired,
    savedVersions: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    selectedVersion: PropTypes.object,
    can_manage_versions: PropTypes.bool.isRequired,
  }

  changeVersion = (e) => {
    this.props.handleAction("changeVersion", e.target.value )
  }

  saveVersion = (data) => {
    this.hideVersionModal()
    this.props.handleAction(data.id ? "updateVersion" : "createVersion", data)
  }

  deleteVersion = () => {
    this.props.handleAction("deleteVersion",this.props.selectedVersion.id)
  }

  pasteVersion = (e) => {
    this.props.handleAction("pasteVersion",e.target.value)
  }

  showVersionModal = () => {
    this.setState({isShowingVersionModal: true})
  }

  hideVersionModal = () => {
    this.setState({isShowingVersionModal: false})
  }


  render() {
    // console.debug("render Versioner with version " + JSON.stringify(this.props.selectedVersion))

    const createVersionTooltip = (
      <Tooltip id="createVersionTooltip">
        Create a new version of this content based on the current one (it needs to be saved)
      </Tooltip>
    )

    const editVersionTooltip = (
      <Tooltip id="editVersionTooltip">
        Edit info of this version
      </Tooltip>
    )

    const deleteVersionTooltip = (
      <Tooltip id="deleteVersionTooltip">
        Delete this version
      </Tooltip>
    )

    // const pasteVersionTooltip = (
    //   <Tooltip id="pasteVersionTooltip">
    //     Paste this version data into the current
    //   </Tooltip>
    // );

    const savedVersions = _.sortBy(this.props.savedVersions, ["timestamp"]).reverse()

    return (
      <React.Fragment>

        <div className="input-group">
          <select className="custom-select" onChange={this.changeVersion} value={this.props.selectedVersion && this.props.selectedVersion.id || "current"}>
            <option value='current' key='current'>Current Version</option>
            {savedVersions.map((version,i) => <option value={version.id} key={i}>{moment(version.timestamp).format("l LT")} {version.name}</option>)}
          </select>
          <div className="input-group-append">
            {
              this.props.can_manage_versions && (
                !this.props.selectedVersion ?
                  <OverlayTrigger placement="top" delayShow={500} overlay={createVersionTooltip}>
                    <Button variant='outline-primary' size="sm" onClick={this.showVersionModal}>
                      <i className='fas fa-plus-circle'></i>
                    </Button>
                  </OverlayTrigger>
                  :
                  <React.Fragment>
                    <OverlayTrigger placement="top" delayShow={500} overlay={editVersionTooltip}>
                      <Button variant='outline-warning' size="sm" onClick={this.showVersionModal}>
                        <i className='fas fa-pencil-alt'></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" delayShow={500} overlay={deleteVersionTooltip}>
                      <Button size="sm" variant="outline-danger" onClick={this.deleteVersion}>
                        <i className='fas fa-trash-alt'></i>
                      </Button>
                    </OverlayTrigger>

                    {/* <OverlayTrigger placement="top" delayShow={500} overlay={pasteVersionTooltip}>
                  <Button size="sm" variant="outline-success" onClick={this.pasteVersion}>
                    <i className="fas fa-paste"></i>
                  </Button>
                </OverlayTrigger> */}
                  </React.Fragment>
              )
            }
          </div>
        </div>

        <AddVersionModal
          show={this.state.isShowingVersionModal}
          onClose={this.hideVersionModal}
          onSubmit={this.saveVersion}
          defaultVersion={this.props.selectedVersion}
          key={this.props.selectedVersion && this.props.selectedVersion.id || "current"} // setting a key unique key make the component AddVersionModal to be rebuilded when its props change. This way its state is rebuilt based on the new props. see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
        />

      </React.Fragment>
    )

  }
}
