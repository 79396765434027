import React, {useEffect, useState, useCallback, memo} from "react"
import PropTypes from "prop-types"
import {Button, Tooltip, OverlayTrigger} from "react-bootstrap"
import {xFetch} from "../../utils"
import DatabaseWidgetModal from "./database_widget_modal.jsx"

function DatabaseWidget(props) {
  const [exportDbData, setExportDbData] = useState()
  const [showDatabaseModal, setShowDatabaseModal] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const findDb = useCallback(
    () => {
      if (!!props.projectId && !!props.currentView.id && !!props.collectorId) {
        setLoading(true)
        xFetch(Routes.project_data_visualizer_export_db_path(props.projectId, props.currentView.id, props.collectorId))
          .then(data => {
            setExportDbData(_.pick(data,"database_params","is_ready","success","last_outcome_message","updated_at"))
          })
          .catch(data => {
            console.warn(data.msgs.join("\n"))
          })
          .finally(() => setLoading(false))
      }
    },
    [
      props.projectId,
      props.currentView.id,
      props.collectorId,
    ]
  )

  const prepareWorkbook = () =>{
    const personIdName = props.workbook.main.idName

    return _.map(_.values(props.workbook), sheet => ({
      name: sheet.tableName,
      fields: _.map(_.filter(sheet.fields, f => (!f.hidden || f.type == "spKey" || f.type == "sfKey") && f.type != "fKey"), f => ({name: f.name, contentType: f.contentType || "string", type: f.type, maxVal: f.maxVal, maxLength: f.maxLength })),
      primaryKey: sheet.idName,
      values: sheet.values.filter(v=> props.exportParameters.selected_row_ids[v[personIdName]]),
      parent: sheet.parent?.tableName,
      parentPrimaryKey: sheet.parent?.idName,
      foreignKey: sheet.parent?.foreignIdName
    })
    )
  }

  useEffect(() => {
    findDb()
  }, [])

  let interval
  useEffect(() => {
    if (exportDbData && !exportDbData.is_ready) {
      interval = setInterval(() => {
        findDb()
      }, 5000)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [exportDbData?.is_ready])

  function createDb() {
    setLoading(true)
    xFetch(Routes.project_data_visualizer_export_db_path(props.projectId, props.currentView.id, props.collectorId), {
      method: "POST",
      body: JSON.stringify({
        id: props.currentView.id,
        collector_id: props.collectorId,
        data_model: JSON.stringify(prepareWorkbook()),
        export_params: JSON.stringify(props.exportParameters)
      })
    })
      .then(data => {
        toastr.info("The request was submitted")
        setExportDbData(_.pick(data,"database_params","is_ready","success","last_outcome_message","updated_at"))
      })
      .catch(error => {
        console.error(error.msgs.join("\n"))
        toastr.error("Unable to submit the request:<br/>" + error.msgs.join("<br/>"))
      })
      .finally(() => setLoading(false))
  }

  function syncDbNow() {
    setLoading(true)
    xFetch(Routes.project_data_visualizer_export_db_path(props.projectId, props.currentView.id, props.collectorId), {
      method: "PUT",
      body: JSON.stringify({
        data_model: JSON.stringify(prepareWorkbook()),
        export_params: JSON.stringify(props.exportParameters)
      })
    })
      .then(data => {
        toastr.info("The request was submitted")
        setExportDbData(_.pick(data,"database_params","is_ready","success","last_outcome_message","updated_at"))
      })
      .catch(error => {
        console.error(error.msgs.join("\n"))
        toastr.error("Unable to submit the request:<br/>" + error.msgs.join("<br/>"))
      })
      .finally(() => setLoading(false))
  }

  function destroyVisualizerDb() {
    setLoading(true)
    return xFetch(Routes.project_data_visualizer_export_db_path(props.projectId, props.currentView.id, props.collectorId), {
      method: "DELETE"
    })
      .then(data => {
        toastr.info("The request was submitted")
        setExportDbData()
      })
      .catch(error => {
        console.error(error.msgs.join("\n"))
        toastr.error("Unable to submit the request:<br/>" + error.msgs.join("<br/>"))
      })
      .finally(() => {
        setLoading(false)
        setShowDatabaseModal(false)
      });
  }

  let tooltipMsg
  if (exportDbData) {

    if (moment(exportDbData.updated_at) < moment(props.currentView.updated_at)) {
      tooltipMsg = <span>Database was not updated after current view last update.  <strong>They may be not synced</strong></span>
    }

    if (!exportDbData.is_ready) {
      tooltipMsg = <span>Database is under construction. Please wait</span>
    } else {
      if (!exportDbData.success) {
        tooltipMsg = <span>Database was not generated. Open for more info</span>
      }
    }
  }

  const warnMessage = tooltipMsg && <OverlayTrigger placement="right" overlay={<Tooltip>{tooltipMsg}</Tooltip>}>
    <i className={`fa fa-exclamation-triangle ${exportDbData.is_ready && !exportDbData.success ? 'text-danger' : 'text-warning'}`}/>
  </OverlayTrigger>

  return <>
    {
      exportDbData ?
        <>
          <Button
            className="btn btn-primary btn-sm mr-2"
            onClick={() => setShowDatabaseModal(true)}
            title={"Show database"}
            disabled={isLoading}
          >
            <span><i className="fa fa-database"/></span> Exported database
          </Button>
          <DatabaseWidgetModal
            show={showDatabaseModal}
            handleClose={setShowDatabaseModal}
            handleSyncDbNow={syncDbNow}
            handleDestroyDb={destroyVisualizerDb}
            handleRefresh={findDb}
            exportDbData={exportDbData}
            canManage={props.canManage}
            isLoading={isLoading}
          />
        </>
        :
        <Button
          className="btn btn-secondary btn-sm"
          onClick={createDb}
          title={"Export as database"}
          disabled={isLoading}
        >
          <span><i className="fa fa-database"/></span> Export workbook as database
        </Button>
    }
    {
      isLoading
      ? <span className="ml-2"><i className="fas fa-spinner fa-spin"></i> </span>
      : warnMessage
    }
  </>
}


DatabaseWidget.propTypes = {
  workbook: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  currentView: PropTypes.object.isRequired,
  collectorId: PropTypes.number.isRequired,
  exportParameters: PropTypes.object.isRequired,
  canManage: PropTypes.bool
}

DatabaseWidget.defaultProps = {
  canManage: false
}

export default memo(DatabaseWidget)
