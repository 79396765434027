import React from 'react'
import PropTypes from 'prop-types'
import OsrIdForm from './osr_id_form'
import IdWidget from '../id_widget'
import { xFetch } from '../utils'

export default function PersonIdManager({
  osr_patient,
  cgp_person,
  biobank_id,
  canAccessOsr,
}) {
  const handleSubmitOsrId = data =>
    xFetch(Routes.update_ids_person_path(cgp_person.public_id), {
      method: 'PATCH',
      headers: { 'X-will-redirect': true },
      body: JSON.stringify(data),
    })
      .then(() => {
        window.location.reload()
      })

  return (
    <>
      {
        canAccessOsr
          ? (
            <OsrIdForm
              canAccessOsr={canAccessOsr}
              currentCgpPerson={cgp_person}
              currentOsrPatient={osr_patient}
              handleSubmit={handleSubmitOsrId}
            />
            )
          : null
      }
      <div className="biobank callout">
        <h4>Biobank ID</h4>
        <IdWidget allowBlank={true} displayName="bioID" editOnClick={true} name="biobank_id" patchUrl={Routes.update_ids_person_path(cgp_person.public_id)} value={biobank_id} />
      </div>
    </>
  )
}

PersonIdManager.propTypes = {
  osr_patient: PropTypes.object,
  cgp_person: PropTypes.object.isRequired,
  biobank_id: PropTypes.string,
  canAccessOsr: PropTypes.bool.isRequired,
}
