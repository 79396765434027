import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SearchPersonWidget from '../search_person_widget/search_person_widget'
import { Modal, Button } from 'react-bootstrap'

export default function OsrIdForm({
  handleSubmit,
  currentCgpPerson,
  currentOsrPatient,
  canAccessOsr,
}) {
  // console.debug('render OsrIdForm')

  const [isShowingModal, setIsShowingModal] = useState(false)

  const handleSelect = id => handleSubmit({ osr_id: id })
  const toggleModal = () => setIsShowingModal(!isShowingModal)

  const actionCell = found_osr_person =>
    found_osr_person.id
      ? <Button disabled size="sm" variant="primary">linked</Button>
      : <Button onClick={() => handleSelect(found_osr_person.osr_id)} size="sm" variant="primary">link</Button>

  const OsrPerson = currentOsrPatient
    ? (
      <p>
        <span className="id">{currentOsrPatient.osr_id}</span>
        <br />
        <small>
          {currentOsrPatient.name}
          {' '}
          {currentOsrPatient.surname}
          {' '}
          {moment(currentOsrPatient.dob).format()}
          <br />
          {currentOsrPatient.taxcode}
        </small>
      </p>
      )
    : (
      <p className="lead">
        no OSR identity linked
      </p>
      )

  const onlyReadSearchParams = {
    sex: currentCgpPerson.sex[0],
    target: ['osr'],
  }

  return (
    <>
      <div className="osr callout">
        <h4>
          OSR ID
        </h4>
        {OsrPerson}
        {canAccessOsr
          ? (
            <div className="text-right">
              {
                currentOsrPatient
                  ? (
                    <Button onClick={() => handleSelect(null)} size="sm" variant="outline-secondary">
                      Unlink the OSR patient
                    </Button>
                    )
                  : (
                    <Button onClick={toggleModal} size="sm" variant="outline-secondary">
                      Link an OSR patient
                    </Button>
                    )
              }
            </div>
            )
          : null}
      </div>

      {canAccessOsr
        ? (
          <Modal
            dialogClassName="modal-90w"
            onHide={toggleModal}
            show={isShowingModal}
          >
            <Modal.Header>
              <Modal.Title>{`Link OSR identity to CGP identity ${currentCgpPerson.id}`}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <SearchPersonWidget
                actionCell={actionCell}
                canAccessOsr={canAccessOsr}
                onlyReadSearchParams={onlyReadSearchParams}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={toggleModal}>Close</Button>
            </Modal.Footer>
          </Modal>
          )
        : null}

    </>
  )
}

OsrIdForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  currentCgpPerson: PropTypes.object.isRequired,
  currentOsrPatient: PropTypes.object,
  canAccessOsr: PropTypes.bool.isRequired,
}
