import React from "react"
import PropTypes from "prop-types"
import { Form } from "react-bootstrap"
import { Row, Col } from "react-bootstrap"

function OptionsManager(props) {

  // console.debug("rendering OptionsManager")

  const handleOptionsChange = e => {
    let newOptions = {
      ...props.options,
      [e.target.id]: e.target.checked
    }
    newOptions.showPersonIdsInAllSheets ||= newOptions.showAllAncestorsKeys // force showPersonIdsInAllSheets option when showAllAncestorsKeys is set

    props.handleOptionsChange(newOptions)
  }

  return <Row className="mb-3">
    <Col>
      <h5 className="font-weight-bold">Secondary sheets options</h5>
      <Form>
        <div>
          <Form.Check
            label="show IDs"
            type="checkbox"
            id="showPersonIdsInAllSheets"
            onChange={handleOptionsChange}
            disabled={props.disabled || props.options.showAllAncestorsKeys} // disable showPersonIdsInAllSheets option when showAllAncestorsKeys is set
            checked={props.options.showPersonIdsInAllSheets}
          />
          <Form.Check
            label="show all ancestor keys"
            type="checkbox"
            id="showAllAncestorsKeys"
            onChange={handleOptionsChange}
            disabled={props.disabled}
            checked={props.options.showAllAncestorsKeys}
          />
          <Form.Check
            label="show sequential ids columns"
            type="checkbox"
            id="showSequentialIds"
            onChange={handleOptionsChange}
            disabled={props.disabled}
            checked={props.options.showSequentialIds}
          />
        </div>
      </Form>
    </Col>
  </Row>
}

OptionsManager.propTypes = {
  options: PropTypes.object.isRequired,
  handleOptionsChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default OptionsManager

