import React from 'react'
import { ReactQuestionFactory, SurveyQuestionRadiogroup } from 'survey-react-ui'
import { SvgIcon } from 'survey-react-ui'

class CgpRadiogroup extends SurveyQuestionRadiogroup {
  renderElement() {
    var cssClasses = this.question.cssClasses

    const svgIcon = <SvgIcon
      className="sd-action__icon"
      size="auto"
      iconName="icon-clear"
      title={this.question.clearButtonCaption}
    />

    // do not render clear button if desidered and ther is a value set
    if (this.question.value !== undefined && this.question.canShowClearButton) {
      var clearButton = (
        <div className="sd-item">
          <button
            className="sd-action sd-action--negative"
            title={this.question.clearButtonCaption}
            type="button"
            onClick={() => this.question.clearValue()}
          >
            {svgIcon}
          </button>
        </div>
      )
    }
    return (
      <fieldset
        aria-describedby={this.question.a11y_input_ariaDescribedBy}
        aria-errormessage={this.question.a11y_input_ariaErrormessage}
        aria-invalid={this.question.a11y_input_ariaInvalid}
        aria-label={this.question.a11y_input_ariaLabel}
        aria-labelledby={this.question.a11y_input_ariaLabelledBy}
        aria-required={this.question.a11y_input_ariaRequired}
        className={this.question.getSelectBaseRootCss()}
        ref={fieldset => (this.setControl(fieldset))}
        role={this.question.a11y_input_ariaRole}
      >
        {this.question.hasColumns
          ? this.getColumnedBody(cssClasses)
          : this.getBody(cssClasses)}
        {this.getFooter()}
        {this.question.isOtherSelected ? this.renderOther(cssClasses) : null}
        {clearButton}
      </fieldset>
    )
  }
}

ReactQuestionFactory.Instance.registerQuestion(
  'sv-cgp-radiogroup',
  function (props) {
    return React.createElement(CgpRadiogroup, props)
  }
)
