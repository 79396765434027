import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ButtonGroup, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap"

function ViewsSelector(props) {

  const [viewName, setViewName] = useState("")
  const [viewIsPublic, setViewIsPublic] = useState(false)
  const [formMode, setFormMode] = useState()
  
  const inputField = React.createRef()

  useEffect(() => {
    switch (formMode) {
    case "new":
      setViewName("")
      setViewIsPublic(false)
      inputField.current && inputField.current.focus()
      break
    case "edit":
      setViewName(props.currentView.name)
      setViewIsPublic(!props.currentView.private)
      break
    }
  }, [formMode])

  useEffect(() => {
    if (props.currentView) {
      setFormMode(props.isCurrentViewChangedContent ? "edit" : null)
    }
  }, [props.isCurrentViewChangedContent])

  const createView = () => props.onCreate(viewName,viewIsPublic).then(setFormMode)

  const updateView = () => props.onUpdate(viewName,viewIsPublic).then(setFormMode)

  const deleteView = () => props.onDelete()

  const handleViewNameChanged = e => setViewName(e.target.value)

  const handleViewIsPublicChanged = e => setViewIsPublic(e.target.checked)

  const selectView = e => {
    props.onSelect(e.target.value && parseInt(e.target.value))
  }

  const viewOptions = props.list.map(v => {
    let label = `${v.name} - ${v.creator} ${v.private ? "" : "PUBLIC"}`
    return <option key={v.id} value={v.id}>{label}</option>
  })

  let warnMessage
  if (props.isCurrentViewChangedContent) {
    const tooltip =
     <Tooltip>
       Current view has changed. <strong>Remember to save!</strong>.
     </Tooltip>

    warnMessage = <OverlayTrigger placement="right" overlay={tooltip}>
      <i className="fa fa-exclamation-triangle text-danger"/>
    </OverlayTrigger>
  }

  return <div className="mb-3">
    {
      formMode ?
        <Form inline>
          <Form.Control
            type="text"
            placeholder="Enter a name ..."
            value={viewName}
            onChange={handleViewNameChanged}
            className="mr-2"
            ref={ref => inputField.current = ref }
          />
          <Form.Check className="mr-2">
            <Form.Check.Label>
              <Form.Check.Input type="checkbox" checked={viewIsPublic} onChange={handleViewIsPublicChanged}/>
              public
            </Form.Check.Label>
          </Form.Check>

          {
            formMode == "edit" ?
              <>
                <ButtonGroup className="ml-2 mr-2">
                  <Button variant="secondary" onClick={() => {
                    props.onSelect(props.currentView.id)
                    setViewName(props.currentView.name)
                    setViewIsPublic(!props.currentView.private)
                    setFormMode()
                  }}>
                    <i className="fas fa-undo"/>
                  </Button>
                  <Button variant="primary" onClick={updateView}>
                    <i className="fa fa-check"/>
                  </Button>
                </ButtonGroup>
                {warnMessage}
              </>
              :
              <ButtonGroup className="ml-2">
                <Button variant="secondary" onClick={() => {
                  setViewName("")
                  setViewIsPublic(false)
                  setFormMode()
                }}>
                  <i className="fas fa-undo"/>
                </Button>
                <Button variant="primary" onClick={createView}>
                  <i className="fa fa-check"/>
                </Button>
              </ButtonGroup>
          }
        </Form>
        :
        <Form inline>
          <Form.Group bsPrefix="form-group visible-*-inline-block" controlId="formControlsSelect">
            <Form.Control onChange={selectView} value={props.currentView?.id} as="select" placeholder="select">
              <option value="">Select a view ...</option>
              {viewOptions}
            </Form.Control>
          </Form.Group>
          {
            props.currentView ?
              <>
                {
                  props.currentView.manageable &&
                    <ButtonGroup className="ml-2">
                      <Button variant="link" onClick={() => setFormMode("edit")}>
                        <i className="fa fa-pencil-alt"/>
                      </Button>
                      <Button variant="link" onClick={deleteView}>
                        <i className="fa fa-trash text-danger"/>
                      </Button>
                    </ButtonGroup>
                }
                <Button className="ml-2" variant="primary" onClick={() => props.onDuplicate()} >
                  <i className="fa fa-clone"/>
                </Button>
              </>
              :
              <Button className="ml-2" onClick={() => setFormMode("new")}>
                <i className="fa fa-plus-circle"/>
              </Button>
          }
        </Form>
    }
  </div>
}

ViewsSelector.propTypes = {
  list: PropTypes.array,
  currentView: PropTypes.object,
  isCurrentViewChangedContent: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired
}

export default ViewsSelector
