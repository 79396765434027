import React, { useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { bootstrapSelectStyle, xFetch } from "../components/utils"

function SelectLocaleWidget(props) {

  const
    options = _.map(props.localeLabels,(label,value) => ({value: value, label: label})),
    currentLocale = {value: props.locale, label: props.localeLabels[props.locale]}

  const setLocale = locale => {
    xFetch(Routes.save_preferred_locale_path(locale.value)).then(() => window.location.reload())
  }

  return <Select
    menuPlacement="top"
    styles={bootstrapSelectStyle}
    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
    value={currentLocale}
    options={options}
    onChange={setLocale}
  />

}

SelectLocaleWidget.propTypes = {
  localeLabels: PropTypes.object.isRequired,
  locale: PropTypes.string
}

export default SelectLocaleWidget
