import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import AddNamedPersonWidget from './add_named_person_widget'
import AddPersonWidget from './add_person_widget'
import ResourcesLister from './resources_lister/resources_lister'

export default function ProjectParticipantsLister({
  attributes = [],
  cohorts = [],
  personSjsModel,
  itemsPath,
  canAccessOsr,
  anonym,
  canDownload,
}) {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false)
  const [lastMemberAdded, setLastMemberAdded] = useState()

  const toggle = () => setShowAddMemberModal(!showAddMemberModal)

  const displayPerson = person => person.fullname || person.id

  const addMember = (person) => {
    setLastMemberAdded(person.id)
    setShowAddMemberModal(false)
    toastr.success(`${displayPerson(person)} was added`)
  }

  const availableCohorts = cohorts.filter(c => c.can_add_remove_members)

  return (
    <>
      <ResourcesLister
        attributes={attributes}
        downloadEnabled={canDownload}
        initialSorting={
          [{
            id: 'created_at',
            desc: true,
          }]
        }
        itemsPath={itemsPath}
        key={lastMemberAdded}
      />
      {
        availableCohorts.length
          ? (
            <>
              <Button onClick={toggle} variant="primary">
                <i className="fas fa-plus-circle mr-2" />
                Add Participant
              </Button>
              <Modal
                dialogClassName="modal-90w"
                onHide={toggle}
                show={showAddMemberModal}
              >
                <Modal.Header>
                  <Modal.Title>Add Participant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {
                  anonym
                    ? (
                      <AddPersonWidget
                        afterAddPerson={addMember}
                        availableCohorts={availableCohorts}
                        personSjsModel={personSjsModel}
                      />
                      )
                    : (
                      <AddNamedPersonWidget
                        afterAddPerson={addMember}
                        availableCohorts={availableCohorts}
                        canAccessOsr={canAccessOsr}
                        personSjsModel={personSjsModel}
                      />
                      )
                }
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={toggle}>Close</Button>
                </Modal.Footer>
              </Modal>
            </>
            )
          : null
      }
    </>
  )
}

ProjectParticipantsLister.propTypes = {
  attributes: PropTypes.object,
  cohorts: PropTypes.array.isRequired,
  personSjsModel: PropTypes.object.isRequired,
  itemsPath: PropTypes.string.isRequired,
  canAccessOsr: PropTypes.bool.isRequired,
  anonym: PropTypes.bool,
}
