import React from "react"
import PropTypes from "prop-types"
import {CopyToClipboard} from "react-copy-to-clipboard"
import { xFetch } from "./utils"

export default class IdWidget extends React.Component {

  static propTypes = {
    value: PropTypes.string,
    patchUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    editOnClick: PropTypes.bool,
    allowBlank: PropTypes.bool
  }

  static defaultProps = {
    editOnClick: false,
    allowBlank: false
  }

  state = {
    value: this.props.value,
    editing: false
  }

  checkPresence = () => this.props.allowBlank || !_.isEmpty(this.domElm.textContent)

  edit = () => {
    this.setState({
      editing: true
    }, () => {
      this.domElm.focus()
    })
  }

  save = () => {
    this.checkPresence() &&
    xFetch(this.props.patchUrl, {
      method: "PATCH",
      body: JSON.stringify({[this.props.name]: this.domElm.textContent})
    })
      .then(() => {
        this.setState({
          editing: false,
          value: this.domElm.textContent
        },() => toastr.success(`${this.props.displayName} correctly updated`))
      })
      .catch(error => {
        toastr.error(_.join(error.msgs, "<br>"), "Id was not updated")
      })

  }

  cancel = (e) => {
    e.stopPropagation()
    this.domElm.textContent = this.state.value
    this.setState({
      editing: false,
      value: this.state.value
    })
  }

  isValueChanged = () => {
    return this.state.value !== this.domElm.textContent
  }

  handleKeyDown = (e) => {
    const { key } = e
    switch (key) {
    case "Enter":
      this.save()
      break
    case "Escape":
      this.cancel(e)
      break
    }
  }

  render() {
    return (
      <div className="btn-group">
        <div className="btn btn-labeled id">
          <span className="btn-label">
            {this.props.displayName}
          </span>
          {
            (!this.state.editing && !this.state.value) ?
              <span className='text-muted'>undefined</span>
              :
              <span
                suppressContentEditableWarning={true}
                contentEditable={this.state.editing && this.props.editOnClick}
                ref={(domNode) => {
                  this.domElm = domNode
                }}
                onKeyDown={this.handleKeyDown}
                style={{display: "inline-block"}}
              >
                {this.state.value}
              </span>
          }
        </div>
        {
          !this.state.editing && this.state.value &&
          <CopyToClipboard text={this.state.value}>
            <div className="btn btn-default">
              <i className="fa fa-copy"/>
            </div>
          </CopyToClipboard>
        }
        {
          this.state.editing ?
            <React.Fragment>
              <div className='btn btn-default' onClick={this.save}>
                <i className="fa fa-check" />
              </div>
              <div className='btn btn-default' onClick={this.cancel}>
                <i className="fa fa-undo" />
              </div>
            </React.Fragment>
            :
            this.props.editOnClick &&
          <div className='btn btn-default' onClick={this.edit}>
            <i className="fa fa-pen" />
          </div>
        }
      </div>
    )
  }
}

